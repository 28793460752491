import { RouteType } from "@routes/interfaces"

const motoristaRoutes: RouteType[] = [
    {
        key: 'cliente',
        path: 'cadastro/motorista',
        title: 'Cliente',
        userVerification: true,
        subRoutes: [
            { key: 'motoristas', path: '', title: 'Motoristas', userVerification: true },
        ]
    }
]

export default motoristaRoutes