import React from 'react'
import { Form as FormikForm, Formik } from 'formik'
import Form from '@components/Form'
import Modal from '@components/Modal'
import Button from '@components/Button'
import ModalActions from '@components/Modal/Actions'
import { ModalProps } from '@interfaces/Modal'
import { createMotorista } from '@modules/Cadastros/Motorista/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { createContratanteFormSchema, createContratanteFormValues } from './config'

const ModalCreate: React.FC <ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { requestCreateMotorista } = useAppSelector(s => s.requestMotorista)

    return(

        <Modal {...props} title = "Criar motorista">
            <Formik
                initialValues = {{
                    ...createContratanteFormValues,
                    idUsuarioCadastro: userData!.id
                }}
                validationSchema = {createContratanteFormSchema}
                onSubmit = {v => createMotorista(dispatch, v, userData!)}
            >
                {() => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Row columns = {2}>
                                <Form.Group inputID = "nome" inputName = "nome" label = "Nome" />
                                <Form.Group inputID = "cpf" inputName = "cpf" label = "CPF" />
                            </Form.Row>
                            <Form.Row columns = {2}>
                                <Form.Group inputID = "nroCNH" inputName = "nroCNH" label = "CNH" />
                                <Form.Group inputID = "rg" inputName = "rg" label = "RG" />
                            </Form.Row>
                                
                            <ModalActions>
                                <Button
                                    type = "reset"
                                    label = "Cancelar"
                                    onClick = {() => props.setOpen(false)}
                                />
                                <Button
                                    type = "submit"
                                    label = "Criar"
                                    loading = {requestCreateMotorista.loading}
                                    status = "success"
                                />
                            </ModalActions>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </Modal>

    )

}

export default ModalCreate