import * as yup from 'yup'

export const createContratanteFormValues = {
    nome: '',
    inscricaoEstadual: '',
    inscricaoMunicipal: '',
    nroDocumento: '',
    endereco: '',
    bairro: '',
    uf: '',
    razaoSocial:''
}

export const createContratanteFormSchema = yup.object().shape({
    nome: yup.string().required('Campo obrigatório'),
    inscricaoEstadual: yup.string().required('Campo obrigatório'),
    inscricaoMunicipal: yup.string().required('Campo obrigatório'),
    nroDocumento: yup.string().required('Campo obrigatório'),
    endereco: yup.string().required('Campo obrigatório'),
    bairro: yup.string().required('Campo obrigatório'),
    uf: yup.string().required('Campo obrigatório'),
})
