import { RouteType } from "@routes/interfaces"

const clienteRoutes: RouteType[] = [
    {
        key: 'cliente',
        path: 'cadastro/cliente',
        title: 'Cliente',
        userVerification: true,
        subRoutes: [
            { key: 'clientes', path: '', title: 'Clientes', userVerification: true },
            { key: 'cliente', path: ':idContratante', title: 'cliente', userVerification: true },
        ]
    }
]

export default clienteRoutes