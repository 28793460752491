import React from 'react'
import { Form as FormikForm, Formik } from 'formik'
import Form from '@components/Form'
import Modal from '@components/Modal'
import Button from '@components/Button'
import ModalActions from '@components/Modal/Actions'
import { ModalProps } from '@interfaces/Modal'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { updateContratanteFormSchema } from './config'
import { updateMotorista } from '@modules/Cadastros/Motorista/controllers'

const ModalUpdate: React.FC <ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { currentMotorista } = useAppSelector(s => s.motoristas)
    const { requestUpdateCliente } = useAppSelector(s => s.requestCliente)

    return(

        <Modal {...props} title = "Editar motorista">
            {!!currentMotorista && (
                <Formik
                    initialValues = {{
                        id: currentMotorista.id,
                        nome: currentMotorista.nome,
                        cpf: currentMotorista.cpf,
                        rg: currentMotorista.rg,
                        nroCNH: currentMotorista.nroCNH,
                        idUsuarioAlteracao: userData!.id
                    }}
                    validationSchema = {updateContratanteFormSchema}
                    onSubmit = {v => updateMotorista(dispatch, v, userData!)}
                >
                    {() => (
                        <FormikForm>
                            <Form.Container padding = {false}>
                            <Form.Row columns = {2}>
                                <Form.Group inputID = "nome" inputName = "nome" label = "Nome" />
                                <Form.Group inputID = "cpf" inputName = "cpf" label = "CPF" />
                            </Form.Row>
                            <Form.Row columns = {2}>
                                <Form.Group inputID = "nroCNH" inputName = "nroCNH" label = "CNH" />
                                <Form.Group inputID = "rg" inputName = "rg" label = "RG" />
                            </Form.Row>
                                    
                                <ModalActions>
                                    <Button
                                        type = "reset"
                                        label = "Cancelar"
                                        onClick = {() => props.setOpen(false)}
                                    />
                                    <Button
                                        type = "submit"
                                        label = "Editar"
                                        loading = {requestUpdateCliente.loading}
                                        status = "success"
                                    />
                                </ModalActions>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            )}
        </Modal>

    )

}

export default ModalUpdate