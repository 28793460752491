import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { Contratante } from "../interfaces/Contratante"

interface State {
    requestGetContratantes: ResponseDefault<Contratante[]>
    requestGetContratante: ResponseDefault<any>
    requestCreateContratante: ResponseDefault<any>
    requestUpdateContratante: ResponseDefault<any>
    requestDeleteContratante: ResponseDefault<any>
}

const initialState: State = {
    requestGetContratantes: responseInitialValues,
    requestGetContratante: responseInitialValues,
    requestCreateContratante: responseInitialValues,
    requestUpdateContratante: responseInitialValues,
    requestDeleteContratante: responseInitialValues,
}

const requestContratanteSlice = createSlice({
    name: 'requestContratante',
    initialState,
    reducers: {
        setRequestGetContratantesData: (state: State, action: PayloadAction<ResponsePattern<Contratante[]>>) => {
            state.requestGetContratantes.data = action.payload
            state.requestGetContratantes.loading = false
            state.requestGetContratantes.error = false
        },
        setRequestGetContratantesLoading: (state: State) => {
            state.requestGetContratantes.loading = true
            state.requestGetContratantes.error = false
        },
        setRequestGetContratantesError: (state: State) => {
            state.requestGetContratantes.loading = false
            state.requestGetContratantes.error = true
        },
        setRequestGetContratantesMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetContratantes.message = action.payload
        },
        resetRequestGetContratantes: (state: State) => {
            state.requestGetContratantes = {...responseInitialValues}
        },

        setRequestGetContratanteData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestGetContratante.data = action.payload
            state.requestGetContratante.loading = false
            state.requestGetContratante.error = false
        },
        setRequestGetContratanteLoading: (state: State) => {
            state.requestGetContratante.loading = true
            state.requestGetContratante.error = false
        },
        setRequestGetContratanteError: (state: State) => {
            state.requestGetContratante.loading = false
            state.requestGetContratante.error = true
        },
        setRequestGetContratanteMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetContratante.message = action.payload
        },
        resetRequestGetContratante: (state: State) => {
            state.requestGetContratante = {...responseInitialValues}
        },

        setRequestCreateContratanteData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestCreateContratante.data = action.payload
            state.requestCreateContratante.loading = false
            state.requestCreateContratante.error = false
        },
        setRequestCreateContratanteLoading: (state: State) => {
            state.requestCreateContratante.loading = true
            state.requestCreateContratante.error = false
        },
        setRequestCreateContratanteError: (state: State) => {
            state.requestCreateContratante.loading = false
            state.requestCreateContratante.error = true
        },
        setRequestCreateContratanteMessage: (state: State, action: PayloadAction<string>) => {
            state.requestCreateContratante.message = action.payload
        },
        resetRequestCreateContratante: (state: State) => {
            state.requestCreateContratante = {...responseInitialValues}
        },

        setRequestUpdateContratanteData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestUpdateContratante.data = action.payload
            state.requestUpdateContratante.loading = false
            state.requestUpdateContratante.error = false
        },
        setRequestUpdateContratanteLoading: (state: State) => {
            state.requestUpdateContratante.loading = true
            state.requestUpdateContratante.error = false
        },
        setRequestUpdateContratanteError: (state: State) => {
            state.requestUpdateContratante.loading = false
            state.requestUpdateContratante.error = true
        },
        setRequestUpdateContratanteMessage: (state: State, action: PayloadAction<string>) => {
            state.requestUpdateContratante.message = action.payload
        },
        resetRequestUpdateContratante: (state: State) => {
            state.requestUpdateContratante = {...responseInitialValues}
        },

        setRequestDeleteContratanteData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestDeleteContratante.data = action.payload
            state.requestDeleteContratante.loading = false
            state.requestDeleteContratante.error = false
        },
        setRequestDeleteContratanteLoading: (state: State) => {
            state.requestDeleteContratante.loading = true
            state.requestDeleteContratante.error = false
        },
        setRequestDeleteContratanteError: (state: State) => {
            state.requestDeleteContratante.loading = false
            state.requestDeleteContratante.error = true
        },
        setRequestDeleteContratanteMessage: (state: State, action: PayloadAction<string>) => {
            state.requestDeleteContratante.message = action.payload
        },
        resetRequestDeleteContratante: (state: State) => {
            state.requestDeleteContratante = {...responseInitialValues}
        },
    }
})

const { actions } = requestContratanteSlice

export const requestGetContratantesActions = {
    data: actions.setRequestGetContratantesData,
    loading: actions.setRequestGetContratantesLoading,
    error: actions.setRequestGetContratantesError,
    message: actions.setRequestGetContratantesMessage,
    reset: actions.resetRequestGetContratantes,
}

export const requestGetContratanteActions = {
    data: actions.setRequestGetContratanteData,
    loading: actions.setRequestGetContratanteLoading,
    error: actions.setRequestGetContratanteError,
    message: actions.setRequestGetContratanteMessage,
    reset: actions.resetRequestGetContratante,
}

export const requestCreateContratanteActions = {
    data: actions.setRequestCreateContratanteData,
    loading: actions.setRequestCreateContratanteLoading,
    error: actions.setRequestCreateContratanteError,
    message: actions.setRequestCreateContratanteMessage,
    reset: actions.resetRequestCreateContratante,
}

export const requestUpdateContratanteActions = {
    data: actions.setRequestUpdateContratanteData,
    loading: actions.setRequestUpdateContratanteLoading,
    error: actions.setRequestUpdateContratanteError,
    message: actions.setRequestUpdateContratanteMessage,
    reset: actions.resetRequestUpdateContratante,
}

export const requestDeleteContratanteActions = {
    data: actions.setRequestDeleteContratanteData,
    loading: actions.setRequestDeleteContratanteLoading,
    error: actions.setRequestDeleteContratanteError,
    message: actions.setRequestDeleteContratanteMessage,
    reset: actions.resetRequestDeleteContratante,
}

export default requestContratanteSlice.reducer