import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { ModalProps } from '@interfaces/Modal'
import Form from '@components/Form'
import Modal from '@components/Modal'
import Button from '@components/Button'
import Select from '@components/Select'
import ModalActions from '@components/Modal/Actions'
import { statusItems } from '@constants/selects'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { updateAcessoGrupo } from '@modules/acesso/controllers/grupoController'

const ModalEditar: React.FC <ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { currentAcessoGrupo } = useAppSelector(s => s.acessoGrupo)

    return(

        <>
            {!!currentAcessoGrupo && (
                <Modal {...props} title = "Editar grupo de acesso">
                    <Formik
                        initialValues = {{
                            id: currentAcessoGrupo.id,
                            nome: currentAcessoGrupo.nome,
                            idUsuarioAlteracao: userData!.id,
                            flagAtivo: currentAcessoGrupo.flagAtivo,
                        }}
                        onSubmit = {v => {
                            updateAcessoGrupo(dispatch, userData!, v, props.setOpen)
                        }}
                    >
                        {({ setFieldValue, values }) => (
                            <FormikForm>
                                <Form.Container padding = {false}>
                                    <Form.Row columns = {1}>
                                        <Form.Group inputID = "nome" inputName = "nome" label = "Nome" />
                                    </Form.Row>
                                    <Form.Row columns = {1}>
                                        <Select
                                            type = "outline"
                                            label = "Situação"
                                            field = "ativo"
                                            value = {values.flagAtivo}
                                            setFieldValue = {setFieldValue}
                                            items = {statusItems}
                                        />
                                    </Form.Row>
                                    <ModalActions>
                                        <Button
                                            type = "reset"
                                            label = "Cancelar"
                                            onClick = {() => props.setOpen(false)}
                                        />
                                        <Button
                                            type = "submit"
                                            label = "Editar"
                                            status = "success"
                                        />
                                    </ModalActions>
                                </Form.Container>
                            </FormikForm>
                        )}
                    </Formik>
                </Modal>
            )}
        </>

    )

}

export default ModalEditar