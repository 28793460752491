import React from 'react'

const Contratante: React.FC = () => {

    return(

        <>
            
        </>

    )

}

export default Contratante