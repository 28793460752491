import React from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import createClassName from '@utils/createClassName'
import { setSelectedArmazens } from '../../reducers/armazenEditarReducer'
import { PainelItemProps } from './types'

const PaintelItem: React.FC <PainelItemProps> = ({ hour, dock }) => {

    const dispatch = useAppDispatch()
    const { armazemBloqueios, selectedArmazens } = useAppSelector(s => s.armazemEditar)

    const painelItemClasses = createClassName("selectBox flex-1 min-w-[246px] flex flex-row gap-2 flex-wrap cursor-pointer", [
        armazemBloqueios?.find(({ horario, nroDoca }) => `h${horario}d${nroDoca}` === `h${hour}d${dock}`) && "bg-red-500",
        selectedArmazens.find(({ horario, nroDoca }) => `h${horario}d${nroDoca}` === `h${hour}d${dock}`) && "selected"
    ])

    return(

        <>
            <td
                id = {`h${hour}d${dock}`}
                className = {painelItemClasses}
                data-hour = {hour}
                data-dock = {dock}
                onClick = {() => {
                    //dispatch(setBloqueioValues({ horario: hour, nroDoca: dock }))
                    dispatch(setSelectedArmazens({ horario: hour, nroDoca: dock }))
                    /*
                    if(armazemBloqueios?.find(({ horario, nroDoca }) => `h${horario}d${nroDoca}` === `h${hour}d${dock}`)){
                        dispatch(setOpenModalDesbloquear(true))
                    }else{
                        dispatch(setOpenModalBloquear(true))
                    }
                    */
                }}
            />
        </>

    )

}

export default PaintelItem