import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { formSchema } from './config'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import Form from '@components/Form'
import Button from '@components/Button'
import { getAgendamentos } from '@modules/agendamento/controllers'
import { setCurrentData } from '@modules/agendamento/reducers/agendamentoReducer'
import { getBloqueios } from '@modules/armazem/controllers'

const FormFilter: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { armazens } = useAppSelector(s => s.armazem)
    const { currentAgendamentoLista } = useAppSelector(s => s.agendamento)
    const { requestGetAgendamentos } = useAppSelector(s => s.requestAgendamento)

    const currentArmazem = armazens!.find(f => f.id === currentAgendamentoLista!.idArmazem)!

    return(

        <>
            <Formik
                initialValues = {{
                    data: currentAgendamentoLista!.dtAgendaFormatada.split(' ')[0].split('/').reverse().join('-')
                }}
                validationSchema = {formSchema}
                onSubmit = {({ data }) => {
                    dispatch(setCurrentData(data))
                    getAgendamentos(dispatch, { data, idArmazem: currentArmazem.id.toString() }, userData!)
                    getBloqueios(dispatch, currentArmazem.guid, data, userData!)
                }}
            >
                {({ handleSubmit }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Row columns = {2}>
                                <Form.Group
                                    inputID = "data"
                                    inputName = "data"
                                    inputType = "date"
                                    label = "Data"
                                />
                                <Button
                                    label = "Buscar"
                                    loading = {requestGetAgendamentos.loading}
                                    onClick = {handleSubmit}
                                />
                            </Form.Row>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormFilter