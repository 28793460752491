import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { IMotorista } from "../interfaces/Motorista"

interface State {
    requestGetMotoristas: ResponseDefault<IMotorista[]>
    requestGetMotorista: ResponseDefault<any>
    requestCreateMotorista: ResponseDefault<any>
    requestUpdateMotorista: ResponseDefault<any>
    requestDeleteMotorista: ResponseDefault<any>
}

const initialState: State = {
    requestGetMotoristas: responseInitialValues,
    requestGetMotorista: responseInitialValues,
    requestCreateMotorista: responseInitialValues,
    requestUpdateMotorista: responseInitialValues,
    requestDeleteMotorista: responseInitialValues,
}

const requestMotoristaSlice = createSlice({
    name: 'requestMotorista',
    initialState,
    reducers: {
        setRequestGetMotoristasData: (state: State, action: PayloadAction<ResponsePattern<IMotorista[]>>) => {
            state.requestGetMotoristas.data = action.payload
            state.requestGetMotoristas.loading = false
            state.requestGetMotoristas.error = false
        },
        setRequestGetMotoristasLoading: (state: State) => {
            state.requestGetMotoristas.loading = true
            state.requestGetMotoristas.error = false
        },
        setRequestGetMotoristasError: (state: State) => {
            state.requestGetMotoristas.loading = false
            state.requestGetMotoristas.error = true
        },
        setRequestGetMotoristasMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetMotoristas.message = action.payload
        },
        resetRequestGetMotoristas: (state: State) => {
            state.requestGetMotoristas = {...responseInitialValues}
        },

        setRequestGetMotoristaData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestGetMotorista.data = action.payload
            state.requestGetMotorista.loading = false
            state.requestGetMotorista.error = false
        },
        setRequestGetMotoristaLoading: (state: State) => {
            state.requestGetMotorista.loading = true
            state.requestGetMotorista.error = false
        },
        setRequestGetMotoristaError: (state: State) => {
            state.requestGetMotorista.loading = false
            state.requestGetMotorista.error = true
        },
        setRequestGetMotoristaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetMotorista.message = action.payload
        },
        resetRequestGetMotorista: (state: State) => {
            state.requestGetMotorista = {...responseInitialValues}
        },

        setRequestCreateMotoristaData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestCreateMotorista.data = action.payload
            state.requestCreateMotorista.loading = false
            state.requestCreateMotorista.error = false
        },
        setRequestCreateMotoristaLoading: (state: State) => {
            state.requestCreateMotorista.loading = true
            state.requestCreateMotorista.error = false
        },
        setRequestCreateMotoristaError: (state: State) => {
            state.requestCreateMotorista.loading = false
            state.requestCreateMotorista.error = true
        },
        setRequestCreateMotoristaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestCreateMotorista.message = action.payload
        },
        resetRequestCreateMotorista: (state: State) => {
            state.requestCreateMotorista = {...responseInitialValues}
        },

        setRequestUpdateMotoristaData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestUpdateMotorista.data = action.payload
            state.requestUpdateMotorista.loading = false
            state.requestUpdateMotorista.error = false
        },
        setRequestUpdateMotoristaLoading: (state: State) => {
            state.requestUpdateMotorista.loading = true
            state.requestUpdateMotorista.error = false
        },
        setRequestUpdateMotoristaError: (state: State) => {
            state.requestUpdateMotorista.loading = false
            state.requestUpdateMotorista.error = true
        },
        setRequestUpdateMotoristaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestUpdateMotorista.message = action.payload
        },
        resetRequestUpdateMotorista: (state: State) => {
            state.requestUpdateMotorista = {...responseInitialValues}
        },

        setRequestDeleteMotoristaData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestDeleteMotorista.data = action.payload
            state.requestDeleteMotorista.loading = false
            state.requestDeleteMotorista.error = false
        },
        setRequestDeleteMotoristaLoading: (state: State) => {
            state.requestDeleteMotorista.loading = true
            state.requestDeleteMotorista.error = false
        },
        setRequestDeleteMotoristaError: (state: State) => {
            state.requestDeleteMotorista.loading = false
            state.requestDeleteMotorista.error = true
        },
        setRequestDeleteMotoristaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestDeleteMotorista.message = action.payload
        },
        resetRequestDeleteMotorista: (state: State) => {
            state.requestDeleteMotorista = {...responseInitialValues}
        },
    }
})

const { actions } = requestMotoristaSlice

export const requestGetMotoristasActions = {
    data: actions.setRequestGetMotoristasData,
    loading: actions.setRequestGetMotoristasLoading,
    error: actions.setRequestGetMotoristasError,
    message: actions.setRequestGetMotoristasMessage,
    reset: actions.resetRequestGetMotoristas,
}

export const requestGetMotoristaActions = {
    data: actions.setRequestGetMotoristaData,
    loading: actions.setRequestGetMotoristaLoading,
    error: actions.setRequestGetMotoristaError,
    message: actions.setRequestGetMotoristaMessage,
    reset: actions.resetRequestGetMotorista,
}

export const requestCreateMotoristaActions = {
    data: actions.setRequestCreateMotoristaData,
    loading: actions.setRequestCreateMotoristaLoading,
    error: actions.setRequestCreateMotoristaError,
    message: actions.setRequestCreateMotoristaMessage,
    reset: actions.resetRequestCreateMotorista,
}

export const requestUpdateMotoristaActions = {
    data: actions.setRequestUpdateMotoristaData,
    loading: actions.setRequestUpdateMotoristaLoading,
    error: actions.setRequestUpdateMotoristaError,
    message: actions.setRequestUpdateMotoristaMessage,
    reset: actions.resetRequestUpdateMotorista,
}

export const requestDeleteMotoristaActions = {
    data: actions.setRequestDeleteMotoristaData,
    loading: actions.setRequestDeleteMotoristaLoading,
    error: actions.setRequestDeleteMotoristaError,
    message: actions.setRequestDeleteMotoristaMessage,
    reset: actions.resetRequestDeleteMotorista,
}

export default requestMotoristaSlice.reducer