import React from 'react'
import { Form as FormikForm, Formik } from 'formik'
import Form from '@components/Form'
import Modal from '@components/Modal'
import Button from '@components/Button'
import Select from '@components/Select'
import ModalActions from '@components/Modal/Actions'
import createLogin from '@modules/acesso/scripts/createLogin'
import { createAcessoUsuario } from '@modules/acesso/controllers/usuarioController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import createSelectItems from '@utils/createSelectItems'
import { setOpenModalSaveSolicitacao } from '../../reducers/acessoSolicitacoesListaReducer'
import { saveAcessoSolicitacaoFormSchema } from './config'

const ModalSaveSolicitacao: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { acessoGrupos } = useAppSelector(s => s.acessoGrupo)
    const { currentAcessoSolicitacao } = useAppSelector(s => s.acesso)
    const { openModalSaveSolicitacao } = useAppSelector(s => s.acessoSolicitacoesLista)
    const { requestCreateUsuario } = useAppSelector(s => s.requestAcessoUsuario)

    return(

        <Modal
            title = {`Solicitação - ${currentAcessoSolicitacao?.id}`}
            width = "60%"
            open = {openModalSaveSolicitacao}
            setOpen = {setOpenModalSaveSolicitacao}
        >
            {!!currentAcessoSolicitacao && (
                <Formik
                    initialValues = {{
                        idGrupoAcesso: null,
                        nome: currentAcessoSolicitacao!.nomeResponsavel,
                        documento: currentAcessoSolicitacao!.cpfResponsavel,
                        email: currentAcessoSolicitacao!.email,
                        telefone: currentAcessoSolicitacao!.telefone,
                        login: createLogin(currentAcessoSolicitacao!.nomeResponsavel),
                        senha: '',
                    }}
                    validationSchema = {saveAcessoSolicitacaoFormSchema}
                    onSubmit = {v => createAcessoUsuario(dispatch, userData!, {...v, idUsuarioCadastro: userData!.id}, setOpenModalSaveSolicitacao)}
                >
                    {({ setFieldValue, handleChange, values }) => (
                        <FormikForm>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {1}>
                                    <Form.Group inputID = "nome" inputName = "nome" label = "Nome" />
                                </Form.Row>
                                <Form.Row columns = {1}>
                                    <Form.Group inputID = "documento" inputName = "documento" label = "Documento" />
                                </Form.Row>
                                <Form.Row columns = {2}>
                                    <Form.Group inputID = "email" inputName = "email" inputType = "email" label = "E-mail (Opcional)" />
                                    <Form.Group
                                        inputID = "telefone"
                                        inputName = "telefone"
                                        label = "Telefone"
                                        mask = {['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        handleChange = {handleChange}
                                    />
                                </Form.Row>
                                <Form.Row columns = {1}>
                                    <Form.Group inputID = "login" inputName = "login" label = "Login" />
                                </Form.Row>
                                <Form.Row columns = {1}>
                                    <Select
                                        type = "outline"
                                        label = "Grupo de Acesso"
                                        field = "idGrupoAcesso"
                                        value = {values.idGrupoAcesso}
                                        setFieldValue = {setFieldValue}
                                        items = {createSelectItems(acessoGrupos, 'nome', 'id')}
                                    />
                                </Form.Row>
                                <ModalActions>
                                    <Button
                                        type = "reset"
                                        label = "Cancelar"
                                        onClick = {() => dispatch(setOpenModalSaveSolicitacao(false))}
                                    />
                                    <Button
                                        type = "submit"
                                        label = "Validar"
                                        loading = {requestCreateUsuario.loading}
                                        status = "success"
                                    />
                                </ModalActions>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            )}
        </Modal>

    )

}

export default ModalSaveSolicitacao