import React from 'react'
import List, { ListColumn, ListLoader, ListMessage, ListRow, ListSection } from '@components/List'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { setCurrentArmazem } from '@modules/armazem/reducers/armazemReducer'

const ListArmazens: React.FC = () => {

    const dispatch = useAppDispatch()
    const { armazens } = useAppSelector(s => s.armazem)
    const { requestGetArmazens } = useAppSelector(s => s.requestArmazem)

    const CR = conditionaRender(requestGetArmazens, armazens, true)
    const grid = "150px 1fr 45px 300px"

    return(

        <>
            <List name = "armazens">
                <ListSection type = "header">
                    <ListRow grid = {grid}>
                        {['Armazém', 'Endereço', 'Janelas', 'Email'].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection>
                    <>
                        {CR.LOADING && <ListLoader />}
                        {CR.NODATA && <ListMessage text = "Não há armazéns!" />}
                        {CR.DATA && armazens!.map((item, index) => (
                            <ListRow
                                key = {index}
                                grid = {grid}
                                link = {`/armazem/editar/${item.guid}`}
                                onClick = {() => dispatch(setCurrentArmazem(item))}
                            >
                                <ListColumn>{item.descricao}</ListColumn>
                                <ListColumn>{item.endereco}</ListColumn>
                                <ListColumn>{item.qtdeDocas}</ListColumn>
                                <ListColumn>{item.listaEmail?.split(/,|;/).join(' ')}</ListColumn>
                            </ListRow>
                        ))}
                    </>
                </ListSection>
            </List>
        </> 

    )

}

export default ListArmazens