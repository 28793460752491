import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"
import { ICliente } from "../interfaces/Cliente"

interface State {
    requestGetClientes: ResponseDefault<ICliente[]>
    requestGetCliente: ResponseDefault<any>
    requestCreateCliente: ResponseDefault<any>
    requestUpdateCliente: ResponseDefault<any>
    requestDeleteCliente: ResponseDefault<any>
}

const initialState: State = {
    requestGetClientes: responseInitialValues,
    requestGetCliente: responseInitialValues,
    requestCreateCliente: responseInitialValues,
    requestUpdateCliente: responseInitialValues,
    requestDeleteCliente: responseInitialValues,
}

const requestClienteSlice = createSlice({
    name: 'requestCliente',
    initialState,
    reducers: {
        setRequestGetClientesData: (state: State, action: PayloadAction<ResponsePattern<ICliente[]>>) => {
            state.requestGetClientes.data = action.payload
            state.requestGetClientes.loading = false
            state.requestGetClientes.error = false
        },
        setRequestGetClientesLoading: (state: State) => {
            state.requestGetClientes.loading = true
            state.requestGetClientes.error = false
        },
        setRequestGetClientesError: (state: State) => {
            state.requestGetClientes.loading = false
            state.requestGetClientes.error = true
        },
        setRequestGetClientesMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetClientes.message = action.payload
        },
        resetRequestGetClientes: (state: State) => {
            state.requestGetClientes = {...responseInitialValues}
        },

        setRequestGetClienteData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestGetCliente.data = action.payload
            state.requestGetCliente.loading = false
            state.requestGetCliente.error = false
        },
        setRequestGetClienteLoading: (state: State) => {
            state.requestGetCliente.loading = true
            state.requestGetCliente.error = false
        },
        setRequestGetClienteError: (state: State) => {
            state.requestGetCliente.loading = false
            state.requestGetCliente.error = true
        },
        setRequestGetClienteMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetCliente.message = action.payload
        },
        resetRequestGetCliente: (state: State) => {
            state.requestGetCliente = {...responseInitialValues}
        },

        setRequestCreateClienteData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestCreateCliente.data = action.payload
            state.requestCreateCliente.loading = false
            state.requestCreateCliente.error = false
        },
        setRequestCreateClienteLoading: (state: State) => {
            state.requestCreateCliente.loading = true
            state.requestCreateCliente.error = false
        },
        setRequestCreateClienteError: (state: State) => {
            state.requestCreateCliente.loading = false
            state.requestCreateCliente.error = true
        },
        setRequestCreateClienteMessage: (state: State, action: PayloadAction<string>) => {
            state.requestCreateCliente.message = action.payload
        },
        resetRequestCreateCliente: (state: State) => {
            state.requestCreateCliente = {...responseInitialValues}
        },

        setRequestUpdateClienteData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestUpdateCliente.data = action.payload
            state.requestUpdateCliente.loading = false
            state.requestUpdateCliente.error = false
        },
        setRequestUpdateClienteLoading: (state: State) => {
            state.requestUpdateCliente.loading = true
            state.requestUpdateCliente.error = false
        },
        setRequestUpdateClienteError: (state: State) => {
            state.requestUpdateCliente.loading = false
            state.requestUpdateCliente.error = true
        },
        setRequestUpdateClienteMessage: (state: State, action: PayloadAction<string>) => {
            state.requestUpdateCliente.message = action.payload
        },
        resetRequestUpdateCliente: (state: State) => {
            state.requestUpdateCliente = {...responseInitialValues}
        },

        setRequestDeleteClienteData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestDeleteCliente.data = action.payload
            state.requestDeleteCliente.loading = false
            state.requestDeleteCliente.error = false
        },
        setRequestDeleteClienteLoading: (state: State) => {
            state.requestDeleteCliente.loading = true
            state.requestDeleteCliente.error = false
        },
        setRequestDeleteClienteError: (state: State) => {
            state.requestDeleteCliente.loading = false
            state.requestDeleteCliente.error = true
        },
        setRequestDeleteClienteMessage: (state: State, action: PayloadAction<string>) => {
            state.requestDeleteCliente.message = action.payload
        },
        resetRequestDeleteCliente: (state: State) => {
            state.requestDeleteCliente = {...responseInitialValues}
        },
    }
})

const { actions } = requestClienteSlice

export const requestGetClientesActions = {
    data: actions.setRequestGetClientesData,
    loading: actions.setRequestGetClientesLoading,
    error: actions.setRequestGetClientesError,
    message: actions.setRequestGetClientesMessage,
    reset: actions.resetRequestGetClientes,
}

export const requestGetClienteActions = {
    data: actions.setRequestGetClienteData,
    loading: actions.setRequestGetClienteLoading,
    error: actions.setRequestGetClienteError,
    message: actions.setRequestGetClienteMessage,
    reset: actions.resetRequestGetCliente,
}

export const requestCreateClienteActions = {
    data: actions.setRequestCreateClienteData,
    loading: actions.setRequestCreateClienteLoading,
    error: actions.setRequestCreateClienteError,
    message: actions.setRequestCreateClienteMessage,
    reset: actions.resetRequestCreateCliente,
}

export const requestUpdateClienteActions = {
    data: actions.setRequestUpdateClienteData,
    loading: actions.setRequestUpdateClienteLoading,
    error: actions.setRequestUpdateClienteError,
    message: actions.setRequestUpdateClienteMessage,
    reset: actions.resetRequestUpdateCliente,
}

export const requestDeleteClienteActions = {
    data: actions.setRequestDeleteClienteData,
    loading: actions.setRequestDeleteClienteLoading,
    error: actions.setRequestDeleteClienteError,
    message: actions.setRequestDeleteClienteMessage,
    reset: actions.resetRequestDeleteCliente,
}

export default requestClienteSlice.reducer