import React from 'react'
import { Form as FormikForm, Formik } from 'formik'
import Form from '@components/Form'
import Modal from '@components/Modal'
import Button from '@components/Button'
import ModalActions from '@components/Modal/Actions'
import { ModalProps } from '@interfaces/Modal'
import { createContrante } from '@modules/contratante/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { createContratanteFormSchema, createContratanteFormValues } from './config'

const ModalCreate: React.FC <ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { requestCreateContratante } = useAppSelector(s => s.requestContratante)

    return(

        <Modal {...props} title = "Criar contratante">
            <Formik
                initialValues = {{
                    ...createContratanteFormValues,
                    idUsuarioCadastro: userData!.id
                }}
                validationSchema = {createContratanteFormSchema}
                onSubmit = {v => createContrante(dispatch, v)}
            >
                {() => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Row columns = {1}>
                                <Form.Group inputID = "nome" inputName = "nome" label = "Nome" />
                            </Form.Row>
                            <Form.Row columns = {1}>
                                <Form.Group inputID = "nroDocumento" inputName = "nroDocumento" label = "Documento" />
                            </Form.Row>
                            <Form.Row columns = {2}>
                                <Form.Group inputID = "inscricaoEstadual" inputName = "inscricaoEstadual" label = "Inscrição Estadual" />
                                <Form.Group inputID = "inscricaoMunicipal" inputName = "inscricaoMunicipal" label = "Inscrição Municipal" />
                            </Form.Row>
                            <Form.Row columns = {3}>
                                <Form.Group inputID = "endereco" inputName = "endereco" label = "Endereço" />
                                <Form.Group inputID = "bairro" inputName = "bairro" label = "Bairro" />
                                <Form.Group inputID = "uf" inputName = "uf" label = "UF" />
                            </Form.Row>
                                
                            <ModalActions>
                                <Button
                                    type = "reset"
                                    label = "Cancelar"
                                    onClick = {() => props.setOpen(false)}
                                />
                                <Button
                                    type = "submit"
                                    label = "Criar"
                                    loading = {requestCreateContratante.loading}
                                    status = "success"
                                />
                            </ModalActions>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </Modal>

    )

}

export default ModalCreate