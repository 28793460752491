import React from 'react'
import { useParams } from 'react-router'
import { mdiCheck } from '@mdi/js'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import ButtonGroup from '@components/Button/Group'
import Button from '@components/Button'
import Modal from '@components/Modal'
import { setOpenModalDesbloquear } from '../../reducers/armazenEditarReducer'
import { setDesbloqueio, setMultipleDesbloqueio } from '@modules/armazem/controllers'

const ModalDesbloquear: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { openModalDesbloquear, bloqueioValues, filterData, selectedArmazens } = useAppSelector(s => s.armazemEditar)
    const { requestSetArmazemDesbloqueio } = useAppSelector(s => s.requestArmazemEditar)
    const { guidArmazem } = useParams()

    const isMultiples = selectedArmazens.length > 0

    return(

        <Modal title = "Desbloquear agendamento" width = {400} open = {openModalDesbloquear} setOpen = {setOpenModalDesbloquear}>
            {isMultiples && <span className = "modalBoxMessage">{`Deseja desbloquear (${selectedArmazens.length}) janelas?`}</span>}
            {(!!bloqueioValues && !isMultiples) && <span className = "modalBoxMessage">{`Deseja desbloquear o agendamento na janela ${bloqueioValues?.nroDoca} às ${bloqueioValues?.horario}?`}</span>}
            {(!bloqueioValues && !isMultiples) && <span className = "modalBoxMessage">{`Deseja desbloquear todo o agendamento do dia ${filterData.split('-').reverse().join('/')}?`}</span>}
            <ButtonGroup>
                <Button
                    label = "Cancelar"
                    onClick = {() => dispatch(setOpenModalDesbloquear(false))}
                />
                <Button
                    icon = {mdiCheck}
                    loading = {requestSetArmazemDesbloqueio.loading}
                    label = "Desbloquear"
                    status = "success"
                    onClick = {() => {
                        if(isMultiples) setMultipleDesbloqueio(dispatch, guidArmazem!, filterData, selectedArmazens, userData!)
                        else setDesbloqueio(dispatch, guidArmazem!, filterData, bloqueioValues, userData!)
                    }}
                />
            </ButtonGroup>
        </Modal>

    )

}

export default ModalDesbloquear