import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ICliente } from "../interfaces/Cliente"

interface State {
    Clientes: ICliente[] | null
    currentCliente: ICliente | null
}

const initialState: State = {
    Clientes: null,
    currentCliente: null,
}

const ClienteSlice = createSlice({
    name: 'Cliente',
    initialState,
    reducers: {
        setClientes: (state, action: PayloadAction<ICliente[] | null>) => {
            state.Clientes = action.payload
        },
        setCurrentCliente: (state, action: PayloadAction<ICliente | null>) => {
            state.currentCliente = action.payload
        },
    }
})

export const {
    setClientes, setCurrentCliente
} = ClienteSlice.actions
export default ClienteSlice.reducer