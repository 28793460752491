import AcessoSolicitacaoLista from "@modules/acesso/pages/AcessoSolicitacaoLista"
import { RoutePageType } from "@routes/interfaces"
import ContaGruposAcesso from "./pages/ContaGruposAcesso"
import ContaUsuarios from "./pages/ContaUsuarios"
import ContaUsuariosAcesso from "./pages/ContaUsuariosAcesso"

const contaPages: RoutePageType[] = [
    {key: 'contaUsuarios', element: ContaUsuarios},
    {key: 'contaUsuariosAcesso', element: ContaUsuariosAcesso},
    {key: 'contaGruposAcesso', element: ContaGruposAcesso},
    {key: 'acessoSolicitacaoLista', element: AcessoSolicitacaoLista}
]

export default contaPages