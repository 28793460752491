import { RouteType } from "@routes/interfaces"

const contratanteRoutes: RouteType[] = [
    {
        key: 'contratante',
        path: 'contratante',
        title: 'Contratante',
        userVerification: true,
        subRoutes: [
            { key: 'contratantes', path: '', title: 'Contratantes', userVerification: true },
            { key: 'contratante', path: ':idContratante', title: 'Contratante', userVerification: true },
        ]
    }
]

export default contratanteRoutes