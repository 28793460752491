const labels = [
    // { label: 'Livre', id: 1 },
    // { label: 'Inativo', id: 2 },
    // { label: 'Reservado', id: 3 },
    // { label: 'Agrup. de Carga', id: 4 },
    { label: 'Carga', id: 5 },
    { label: 'Descarga', id: 6 },
    // { label: 'Faltou', id: 7 },
    // { label: 'Finalizado', id: 8 },
    // { label: 'Atrasado', id: 9 },
    // { label: 'Resp. Check-List', id: 10 },
]

export default labels