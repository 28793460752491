import ENV from "@env/index"
import { UserData } from "@modules/auth/interfaces/UserData"
import { DispatchType } from '@redux/types'
import request from '@utils/request'
import { ResponsePattern } from '@utils/response/types'
import { handleResponse, initRequest } from '@utils/response'
import { AcessoUsuario } from "../interfaces/AcessoUsuario"
import * as R from '../reducers/usuario/requestUsuarioReducer'
import { setAcessoUsuarios } from '../reducers/usuario/usuarioReducer'
import getContratante from "@modules/auth/scripts/getContratante"

export async function getAcessoUsuarios(dispatch: DispatchType, user: UserData){
    initRequest(dispatch, R.requestGetAcessoUsuarios)

    const endpoint = `${ENV.APP_ENDPOINT}/usuarios`
    const params = `/${user.contratante.uuid}`
    const response = await request.get<ResponsePattern<AcessoUsuario[]>>({endpoint, params})

    handleResponse('getAcessoUsuarios', dispatch, response, R.requestGetAcessoUsuarios)
    .then(data => {
        dispatch(setAcessoUsuarios(data))
    })
}

export async function filterGetAcessoUsuarios<T>(dispatch: DispatchType, user: UserData, values: T){
    initRequest(dispatch, R.requestGetAcessoUsuarios)

    const endpoint = `${ENV.APP_ENDPOINT}/usuarios`
    const params = `/${user.contratante.uuid}`
    const response = await request.get<ResponsePattern<AcessoUsuario[]>>({endpoint, params})

    handleResponse('getAcessoUsuarios', dispatch, response, R.requestGetAcessoUsuarios)
    .then(data => {
        dispatch(setAcessoUsuarios(data))
    })
}

export async function createAcessoUsuario<T>(dispatch: DispatchType, user: UserData, values: T, setOpen: Function){
    initRequest(dispatch, R.requestCreateAcessoUsuario)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/usuario`
    const body = {
        ...values,
        ...getContratante(user)
    }
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('createAcessoUsuario', dispatch, response, R.requestCreateAcessoUsuario, options)
    .then(() => {
        getAcessoUsuarios(dispatch, user)
        setOpen(false)
    })
}

export async function editAcessoUsuario<T>(dispatch: DispatchType, user: UserData, values: T, setOpen: Function){
    initRequest(dispatch, R.requestUpdateAcessoUsuario)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/usuario`
    const body = {
        ...values,
        ...getContratante(user)
    }
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('editAcessoUsuario', dispatch, response, R.requestUpdateAcessoUsuario, options)
    .then(() => {
        getAcessoUsuarios(dispatch, user)
        setOpen(false)
    })
}

export async function deleteAcessoUsuario<T>(dispatch: DispatchType, user: UserData, values: T){
    initRequest(dispatch, R.requestRemoveAcessoUsuario)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/usuario/apagar`
    const body = {
        ...values,
        ...getContratante(user)
    }
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('deleteAcessoUsuario', dispatch, response, R.requestRemoveAcessoUsuario, options)
    .then(() => {
        getAcessoUsuarios(dispatch, user)
    })
}
