import { mdiListBoxOutline } from "@mdi/js"
import { RouteType } from "@routes/interfaces"

const relatorioRoutes: RouteType[] = [
    {
        key: 'relatorio',
        path: 'relatorio',
        title: 'Relatório',
        icon: mdiListBoxOutline,
        userVerification: true,
        subRoutes: [
            {key: 'relatorioAgendamento', path: 'agendamentos', title: 'Relatório de Agendamentos', userVerification: true},
        ]
    }
]

export default relatorioRoutes