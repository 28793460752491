import React from 'react'
import { Link } from 'react-router-dom'
import { mdiAccount, mdiApps, mdiBrightness4, mdiBrightness5, mdiChat, mdiCog, mdiLogout } from '@mdi/js'
import './styles.scss'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { setAuthLogout } from '@modules/auth/reducers/authReducer'
import Icon from '@mdi/react'
import Logo from '@assets/images/logo-reserve.png'
import { toggleTheme } from '@modules/theme/reducers/themeReducer'
import { formatLink } from '@utils/format'
import { APP_VERSION } from '@config/index'
import getLinkChat from '@modules/auth/scripts/getLinkChat'

const Header: React.FC = () => {

    const dispatch = useAppDispatch()
    const { authRoutes, userData } = useAppSelector(s => s.auth)
    const { theme } = useAppSelector(s => s.theme)
    const linkChat = getLinkChat(userData)
    
    return(

        <>
            <header className="navbar bg-base-100 gap-6 fixed top-0">
                {/* <div className="flex-none">
                    <button className="btn btn-square btn-ghost" onClick = {() => dispatch(toggleSidebarOpen())}>
                        <Icon path = {mdiMenu} size = "24px" color = "#fff" />
                    </button>
                </div> */}
                <div id = "headerBanner">
                    <Link to = "/">
                        {(APP_VERSION.FLAG_QAS && (
                            <>Reserve QAS</>
                        )) || (
                            <img src={Logo} alt="Comando Reserve" className="w-auto h-12" />
                        )}
                    </Link>
                </div>
                <div className="navbar-center">
                    {authRoutes.map((item, index) => (
                        <ul key = {index} className="menu menu-horizontal p-0">
                            <li>
                                {(formatLink(item.rotas[0].path!).slice(0, -1) !== item.modulo.path && (
                                    <>
                                        <a href= "#">
                                            {item.modulo.nome}
                                            <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                viewBox="0 0 24 24">
                                                <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                                            </svg>
                                        </a>
                                        <ul className="p-2 bg-gray-100 z-[500]">
                                            {item.rotas.map((rota, indexRota) => (
                                                <li key = {indexRota}>
                                                    <Link to = {`${item.modulo.path}${formatLink(rota.path ?? '')}`}>{rota.nome}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )) || (
                                    <a href = {item.modulo.path}>
                                        {item.modulo.nome}
                                    </a>
                                )}
                            </li>
                        </ul>
                    ))}
                    <ul className="menu menu-horizontal p-0">
                        <li>
                            <a href = "/suporte/faq">Suporte</a>
                        </li>
                    </ul>
                </div>
                <div className="flex-1 justify-end">
                    <div className="flex-none">

                       {linkChat && <a href={linkChat}  target="_blank" className="btn btn-square btn-ghost">
                            <Icon path = {mdiChat} size = "24px" color = "#fff" />
                        </a>}
                    </div>
                    <div className="dropdown dropdown-end">
                        <button className="btn btn-square btn-ghost">
                            <Icon path = {mdiApps} size = "24px" color = "#fff" />
                        </button>

                        <ul tabIndex={0} className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52">
                            <li>
                                <button>
                                    <a href = "http://reserve.integralogis.com.br" className="justify-between">
                                        Reserve
                                        <span className="badge">Atual</span>
                                    </a>
                                </button>
                            </li>
                            <li>
                                <button>
                                    <a href = "http://wms.integralogis.com.br">View Stock</a>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="dropdown dropdown-end">
                        <button className="btn btn-ghost btn-square avatar">
                            <Icon path = {mdiCog} size = "24px" color = "#fff" />
                        </button>

                        <ul tabIndex={0} className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52">
                            <li>
                                <button onClick = {() => dispatch(toggleTheme())}>
                                    <a>
                                        <Icon path = {theme === "dark-mode" ? mdiBrightness5 : mdiBrightness4} size = "24px" color = "#fff" />
                                        Trocar tema
                                    </a>
                                </button>
                            </li>
                            <li>
                                <button onClick = {() => dispatch(setAuthLogout())}>
                                    <a>
                                        <Icon path = {mdiLogout} size = "24px" color = "#fff" />
                                        Encerrar sessão
                                    </a>
                                </button>
                            </li>
                        </ul>
                    </div>
                    {!!userData && (
                        <div className="dropdown dropdown-end">
                            <button className="btn btn-ghost btn-square avatar">
                                <Icon path = {mdiAccount} size = "24px" color = "#fff" />
                            </button>

                            <div tabIndex={0} className="mt-3 card card-compact dropdown-content w-52 bg-base-100 shadow">
                                <div className="card-body">
                                    <span className="font-bold text-lg">{userData.nome}</span>
                                    <span className="text-info">{userData.grupoAcesso.nome}</span>

                                    <div id="versionDetails">
                                        <span className="text-info text-center font-bold">{APP_VERSION.DEPLOY_ENV}</span>
                                        <span className="text-info text-center">{APP_VERSION.NAME}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </header>
        </>

    )

}

export default Header