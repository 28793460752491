import ENV from "@env/index"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { ICliente } from "../interfaces/Cliente"
import { requestCreateClienteActions, requestDeleteClienteActions, requestGetClientesActions, requestUpdateClienteActions } from "../reducers/requestClienteReducer"
import { setClientes } from "../reducers/clienteReducer"
import { UserData } from "@modules/auth/interfaces/UserData"
import getContratante from "@modules/auth/scripts/getContratante"

export async function getClientes(dispatch: DispatchType, userData: UserData){
    initRequest(dispatch, requestGetClientesActions)

    const endpoint = `${ENV.APP_ENDPOINT}/clientes/${getContratante(userData).uuidContratante}`

    const response = await request.get<ResponsePattern<ICliente[]>>({ endpoint })

    handleResponse('getClientes', dispatch, response, requestGetClientesActions)
    .then(data => {
        dispatch(setClientes(data))
    })
}

export async function createCliente<T>(dispatch: DispatchType, data: T, userData: UserData){
    initRequest(dispatch, requestCreateClienteActions)

    const body = {
        ...data,
        uuidContratante: getContratante(userData).uuidContratante
    }

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/cliente`
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('createClientes', dispatch, response, requestCreateClienteActions, options)
    .then(() => {
        getClientes(dispatch, userData)
    })
}

export async function updateCliente<T>(dispatch: DispatchType, data: T, userData: UserData){
    initRequest(dispatch, requestUpdateClienteActions)

    const body = {
        ...data,
        uuidContratante: getContratante(userData).uuidContratante
    }

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/cliente`
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('updateClientes', dispatch, response, requestUpdateClienteActions, options)
    .then(() => {
        getClientes(dispatch, userData)
    })
}

export async function deleteCliente<T>(dispatch: DispatchType, body: T, userData: UserData){
    initRequest(dispatch, requestDeleteClienteActions)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/cliente/deletar`
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('deleteClientes', dispatch, response, requestDeleteClienteActions, options)
    .then(() => {
        getClientes(dispatch, userData)
    })
}