import React from 'react'
import Logo from '@assets/images/logo-reserve.png'
import { APP_VERSION } from '@config/index'
import FormLogin from './components/FormLogin'
import './styles.scss'

const Login: React.FC = () => {

    return(

        <>
            <div id="formLoginContainer">
                <div className="loginContent">
                    <div className="loginGradient">
                        {(APP_VERSION.FLAG_QAS && (
                            <h1>Reserve QAS</h1>
                        )) || (
                            <img src={Logo} alt="" />
                        )}
                    </div>
                </div>
                <div className="loginContent">
                    <FormLogin />
                </div>
            </div>
        </>

    )

}

export default Login