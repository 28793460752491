import React from 'react'
import { mdiMagnify } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import { formSchema, formValues } from './config'
import Form from '@components/Form'
import Select from '@components/Select'
import createSelectItems from '@utils/createSelectItems'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import Button from '@components/Button'
import { getAgendamentos } from '@modules/agendamento/controllers'
import { setCurrentArmazem } from '@modules/agendamento/reducers/agendamentoReducer'

const FormFilter: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { armazens } = useAppSelector(s => s.armazem)
    const { requestGetAgendamentos } = useAppSelector(s => s.requestAgendamento)

    return(

        <>
            <Formik
                initialValues = {formValues}
                validationSchema = {formSchema}
                onSubmit = {v => getAgendamentos(dispatch, v, userData!, armazens!.find(f => f.id === Number(v.idArmazem))!.guid)}
            >
                {({ handleSubmit, setFieldValue, values }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Row columns = {3}>
                                <Select
                                    type = "outline"
                                    label = "Armazém"
                                    value = {values.idArmazem}
                                    field = "idArmazem"
                                    setFieldValue = {setFieldValue}
                                    setValue = {v => dispatch(setCurrentArmazem(v))}
                                    items = {!!armazens ? createSelectItems(armazens, 'descricao', 'id') : []}
                                />
                                <Form.Group inputID = "data" inputName = "data" label = "Data" inputType = "date" />
                                <Button
                                    icon = {mdiMagnify}
                                    label = "Buscar"
                                    status = "info"
                                    loading = {requestGetAgendamentos.loading}
                                    onClick = {handleSubmit}
                                />
                            </Form.Row>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormFilter