import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Cliente } from "@interfaces/Cliente"
import { Motorista } from "@interfaces/Motorista"
import { NotaFiscal } from "@interfaces/NotaFiscal"
import { Agendamento } from "@interfaces/Agendamento"
import { TipoVeiculo } from "@interfaces/TipoVeiculo"
import { NotaFiscalCnpj } from "@interfaces/NotaFiscalCnpj"
import { AgendamentoLista } from "@interfaces/AgendamentoLista"
import { AgendamentoAtual } from "@interfaces/AgendamentoAtual"
import { formValues } from "../pages/AgendamentoConsulta/components/FormFilter/config"

interface State {
    currentArmazem: any

    agendamentos: Agendamento[] | null
    currentAgendamento: Agendamento | null
    currentAgendamentoBloqueado: Agendamento | null
    
    agendamentosBloqueados: Agendamento[] | null
    
    agendamentosLista: AgendamentoLista[] | null
    agendamentoLista: AgendamentoLista | null
    currentAgendamentoLista: AgendamentoLista | null

    agendamento: AgendamentoAtual | null

    agendamentoFilterValues: typeof formValues | null

    currentData: string

    clientes: Cliente[] | null
    tiposVeiculo: TipoVeiculo[] | null
    motoristas: Motorista[] | null
    notasFiscais: NotaFiscal[] | null
    notasFiscaisCnpj: NotaFiscalCnpj[] | null
}

const initialState: State = {
    currentArmazem: 0,
    agendamentos: null,
    agendamentoLista: null,
    currentAgendamento: null,
    currentAgendamentoBloqueado: null,
    agendamentosBloqueados: null,
    agendamentosLista: null,
    currentAgendamentoLista: null,
    agendamento: null,
    agendamentoFilterValues: null,
    currentData: '',
    clientes: null,
    tiposVeiculo: null,
    motoristas: null,
    notasFiscais: null,
    notasFiscaisCnpj: null
}

const agendamentoSlice = createSlice({
    name: 'agendamento',
    initialState,
    reducers: {
        setCurrentArmazem: (state, action: PayloadAction<any>) => {
            state.currentArmazem = action.payload
        },

        setAgendamentos: (state, action: PayloadAction<Agendamento[]>) => {
            state.agendamentos = action.payload
        },
        setCurrentAgendamento: (state, action: PayloadAction<Agendamento>) => {
            state.currentAgendamento = action.payload
        },
        setCurrentAgendamentoBloqueado: (state, action: PayloadAction<Agendamento>) => {
            state.currentAgendamentoBloqueado = action.payload
        },

        setAgendamentosBloqueados: (state, action: PayloadAction<Agendamento[]>) => {
            state.agendamentosBloqueados = action.payload
        },

        setAgendamentosLista: (state, action: PayloadAction<AgendamentoLista[]>) => {
            state.agendamentosLista = action.payload
        },
        setCurrentAgendamentoLista: (state, action: PayloadAction<AgendamentoLista>) => {
            state.currentAgendamentoLista = action.payload
        },

        setAgendamentoLista: (state, action: PayloadAction<AgendamentoLista>) => {
            state.agendamentoLista = action.payload
        },

        setAgendamento: (state, action: PayloadAction<AgendamentoAtual | null>) => {
            state.agendamento = action.payload
        },

        setAgendamentoFilterValues: (state, action: PayloadAction<typeof formValues>) => {
            state.agendamentoFilterValues = action.payload
        },

        setCurrentData: (state, action: PayloadAction<string>) => {
            state.currentData = action.payload
        },

        setTiposVeiculos: (state, action: PayloadAction<TipoVeiculo[]>) => {
            state.tiposVeiculo = action.payload
        },
        setClientes: (state, action: PayloadAction<Cliente[]>) => {
            state.clientes = action.payload
        },
        setMotoristas: (state, action: PayloadAction<Motorista[]>) => {
            state.motoristas = action.payload
        },
        setNotasFiscais: (state, action: PayloadAction<NotaFiscal[]>) => {
            state.notasFiscais = action.payload
        },
        setNotasFiscaisCnpj: (state, action: PayloadAction<NotaFiscalCnpj[]>) => {
            state.notasFiscaisCnpj = action.payload
        }
    }
})

export const {
    setAgendamentos, setCurrentAgendamento, setCurrentAgendamentoBloqueado, setAgendamento, setAgendamentoFilterValues, setAgendamentosBloqueados,
    setAgendamentosLista, setCurrentAgendamentoLista,
    setAgendamentoLista,
    setCurrentArmazem,
    setCurrentData,
    setClientes, setTiposVeiculos, setMotoristas, setNotasFiscais, setNotasFiscaisCnpj
} = agendamentoSlice.actions
export default agendamentoSlice.reducer