import { SelectItem } from "@components/Select/types"

export const activeItems: SelectItem[] = [
    {label: 'Habilitado', value: 1},
    {label: 'Desabilitado', value: 0},
]

export const statusItems: SelectItem[] = [
    {label: 'Habilitado', value: true},
    {label: 'Desabilitado', value: false},
]