import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import TableNfCnpj from '@modules/agendamento/components/NotaFiscal/Table'
import FormSearchNotaFiscalCnpj from '@modules/agendamento/components/NotaFiscal/FormSearch'
import { getAgendamentoNotasFiscaisCnpj, getAgendamentoNotasFiscaisNr } from '@modules/agendamento/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import ListNotaFiscal from '../../components/NotaFiscal/List'
import FormAddNotaFiscal from '../../components/NotaFiscal/FormAdd'

const AgendamentoCadastroFiscal: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)

    const loadNF = () => {
        if(!!userData && !!userData.contratante.nroDocumento){
            getAgendamentoNotasFiscaisCnpj(dispatch, userData, userData.contratante.nroDocumento)
        }
    }

    useEffect(() => {
        loadNF()
    }, [dispatch, userData])

    return(

        <>
            <Section>
                <SectionBox title = "Adicionar notas fiscais" padding = {false}>
                    <FormAddNotaFiscal nextStep = {true} />
                </SectionBox>
                <SectionBox
                    title = "Pesquisar notas fiscais"
                    padding = {false}
                    right = {
                        <FormSearchNotaFiscalCnpj
                            handleSearch = {v => {
                                getAgendamentoNotasFiscaisNr(dispatch, userData!, v)
                            }}
                            handleReset = {() => loadNF()}
                        />
                    }
                >
                    <TableNfCnpj />
                </SectionBox>
                <SectionBox title = "Notas fiscais adicionadas" padding = {false}>
                    <ListNotaFiscal />
                </SectionBox>
            </Section>
        </>

    )

}

export default AgendamentoCadastroFiscal