import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Contratante } from "../interfaces/Contratante"

interface State {
    contratantes: Contratante[] | null
    currentContratante: Contratante | null
}

const initialState: State = {
    contratantes: null,
    currentContratante: null
}


const contratanteSlice = createSlice({
    name: 'contratante',
    initialState,
    reducers: {
        setContratantes: (state, action: PayloadAction<Contratante[] | null>) => {
            state.contratantes = action.payload
        },
        setCurrentContratante: (state, action: PayloadAction<Contratante | null>) => {
            state.currentContratante = action.payload
        },
    }
})

export const {
    setContratantes, setCurrentContratante
} = contratanteSlice.actions
export default contratanteSlice.reducer