import ENV from "@env/index"
import { UserData } from "../interfaces/UserData"
import * as R from '../reducers/requestAuthReducer'
import { setAuthLogin } from "../reducers/authReducer"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse } from "@utils/response"
import handleContratanteByLink from "../utils/handleContratanteByLink"

export default async function handleLogin(dispatch: DispatchType, setRedirect: Function, values: any){
    dispatch(R.requestSendAuthLogin.reset())
    dispatch(R.requestSendAuthLogin.loading())

    const options = {messages: {errorMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/usuario/login`
    const body = { ...values, idAplicativo: 1 }
    const response = await request.post<ResponsePattern<UserData>>({endpoint, body})

    handleResponse("handleLogin", dispatch, response, R.requestSendAuthLogin, options)
    .then(data => {
        dispatch(setAuthLogin({
            ...data,
            contratante: {
                ...data.contratante,
                uuid: handleContratanteByLink(data)
            }
        }))

        setRedirect(true)
    })
}