import * as yup from 'yup'

export const updateContratanteFormValues = {
    nome: '',
    inscricaoEstadual: '',
    inscricaoMunicipal: '',
    nroDocumento: '',
    endereco: '',
    bairro: '',
    uf: '',
    razaoSocial:''
}

export const updateContratanteFormSchema = yup.object().shape({
    nome: yup.string().required('Campo obrigatório')
})
