import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Cliente } from "@interfaces/Cliente"
import { Motorista } from "@interfaces/Motorista"
import { NotaFiscal } from "@interfaces/NotaFiscal"
import { Agendamento } from "@interfaces/Agendamento"
import { TipoVeiculo } from "@interfaces/TipoVeiculo"
import { NotaFiscalCnpj } from "@interfaces/NotaFiscalCnpj"
import { AgendamentoAtual } from "@interfaces/AgendamentoAtual"
import { AgendamentoLista } from "@interfaces/AgendamentoLista"
import { responseInitialValues } from "@utils/response"
import { ResponseDefault, ResponsePattern } from "@utils/response/types"

interface State {
    requestGetAgendamentos: ResponseDefault<Agendamento[]>
    requestGetAgendamentosBloqueados: ResponseDefault<Agendamento[]>
    requestGetAgendamentosLista: ResponseDefault<AgendamentoLista[]>
    requestGetAgendamentoLista: ResponseDefault<AgendamentoLista[]>
    requestGetAgendamento: ResponseDefault<AgendamentoAtual[]>
    requestSaveAgendamento: ResponseDefault<any>
    requestGetTiposVeiculo: ResponseDefault<TipoVeiculo[]>
    requestGetClientes: ResponseDefault<Cliente[]>
    requestCreateAgendamento: ResponseDefault<any>
    requestGetMotoristas: ResponseDefault<Motorista[]>
    requestGetNotasFiscais: ResponseDefault<NotaFiscal[]>
    requestSaveNotaFiscal: ResponseDefault<any>
    requestRemoveNotaFiscal: ResponseDefault<any>
    requestSaveAgendamentoHorario: ResponseDefault<any>
    requestRemoveAgendamento: ResponseDefault<any>
    requestEditAgendamento: ResponseDefault<any>
    requestSetAgendamentoDebloqueio: ResponseDefault<any>
    requestGetNotasFiscaisCnpj: ResponseDefault<NotaFiscalCnpj[]>
    requestGetNotasFiscaisNr: ResponseDefault<NotaFiscalCnpj[]>
    requestVincularAgendamentoNotaFiscal: ResponseDefault<any>
    requestGerarDanfe: ResponseDefault<any>
}

const initialState: State = {
    requestGetAgendamentos: responseInitialValues,
    requestGetAgendamentosBloqueados: responseInitialValues,
    requestGetAgendamentosLista: responseInitialValues,
    requestGetAgendamentoLista: responseInitialValues,
    requestGetAgendamento: responseInitialValues,
    requestSaveAgendamento: responseInitialValues,
    requestGetTiposVeiculo: responseInitialValues,
    requestGetClientes: responseInitialValues,
    requestCreateAgendamento: responseInitialValues,
    requestGetMotoristas: responseInitialValues,
    requestGetNotasFiscais: responseInitialValues,
    requestSaveNotaFiscal: responseInitialValues,
    requestRemoveNotaFiscal: responseInitialValues,
    requestSaveAgendamentoHorario: responseInitialValues,
    requestRemoveAgendamento: responseInitialValues,
    requestEditAgendamento: responseInitialValues,
    requestSetAgendamentoDebloqueio: responseInitialValues,
    requestGetNotasFiscaisCnpj: responseInitialValues,
    requestGetNotasFiscaisNr: responseInitialValues,
    requestVincularAgendamentoNotaFiscal: responseInitialValues,
    requestGerarDanfe: responseInitialValues,
}

const requestAgendamentoSlice = createSlice({
    name: 'requestAgendamento',
    initialState,
    reducers: {
        setRequestGetAgendamentosData: (state: State, action: PayloadAction<ResponsePattern<Agendamento[]>>) => {
            state.requestGetAgendamentos.data = action.payload
            state.requestGetAgendamentos.loading = false
            state.requestGetAgendamentos.error = false
        },
        setRequestGetAgendamentosLoading: (state: State) => {
            state.requestGetAgendamentos.loading = true
            state.requestGetAgendamentos.error = false
        },
        setRequestGetAgendamentosError: (state: State) => {
            state.requestGetAgendamentos.loading = false
            state.requestGetAgendamentos.error = true
        },
        setRequestGetAgendamentosMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetAgendamentos.message = action.payload
        },
        resetRequestGetAgendamentos: (state: State) => {
            state.requestGetAgendamentos = {...responseInitialValues}
        },

        setRequestGetAgendamentosBloqueadosData: (state: State, action: PayloadAction<ResponsePattern<Agendamento[]>>) => {
            state.requestGetAgendamentosBloqueados.data = action.payload
            state.requestGetAgendamentosBloqueados.loading = false
            state.requestGetAgendamentosBloqueados.error = false
        },
        setRequestGetAgendamentosBloqueadosLoading: (state: State) => {
            state.requestGetAgendamentosBloqueados.loading = true
            state.requestGetAgendamentosBloqueados.error = false
        },
        setRequestGetAgendamentosBloqueadosError: (state: State) => {
            state.requestGetAgendamentosBloqueados.loading = false
            state.requestGetAgendamentosBloqueados.error = true
        },
        setRequestGetAgendamentosBloqueadosMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetAgendamentosBloqueados.message = action.payload
        },
        resetRequestGetAgendamentosBloqueados: (state: State) => {
            state.requestGetAgendamentosBloqueados = {...responseInitialValues}
        },

        setRequestGetAgendamentosListaData: (state: State, action: PayloadAction<ResponsePattern<AgendamentoLista[]>>) => {
            state.requestGetAgendamentosLista.data = action.payload
            state.requestGetAgendamentosLista.loading = false
            state.requestGetAgendamentosLista.error = false
        },
        setRequestGetAgendamentosListaLoading: (state: State) => {
            state.requestGetAgendamentosLista.loading = true
            state.requestGetAgendamentosLista.error = false
        },
        setRequestGetAgendamentosListaError: (state: State) => {
            state.requestGetAgendamentosLista.loading = false
            state.requestGetAgendamentosLista.error = true
        },
        setRequestGetAgendamentosListaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetAgendamentosLista.message = action.payload
        },
        resetRequestGetAgendamentosLista: (state: State) => {
            state.requestGetAgendamentosLista = {...responseInitialValues}
        },

        setRequestGetAgendamentoListaData: (state: State, action: PayloadAction<ResponsePattern<AgendamentoLista[]>>) => {
            state.requestGetAgendamentoLista.data = action.payload
            state.requestGetAgendamentoLista.loading = false
            state.requestGetAgendamentoLista.error = false
        },
        setRequestGetAgendamentoListaLoading: (state: State) => {
            state.requestGetAgendamentoLista.loading = true
            state.requestGetAgendamentoLista.error = false
        },
        setRequestGetAgendamentoListaError: (state: State) => {
            state.requestGetAgendamentoLista.loading = false
            state.requestGetAgendamentoLista.error = true
        },
        setRequestGetAgendamentoListaMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetAgendamentoLista.message = action.payload
        },
        resetRequestGetAgendamentoLista: (state: State) => {
            state.requestGetAgendamentoLista = {...responseInitialValues}
        },

        setRequestGetAgendamentoData: (state: State, action: PayloadAction<ResponsePattern<AgendamentoAtual[]>>) => {
            state.requestGetAgendamento.data = action.payload
            state.requestGetAgendamento.loading = false
            state.requestGetAgendamento.error = false
        },
        setRequestGetAgendamentoLoading: (state: State) => {
            state.requestGetAgendamento.loading = true
            state.requestGetAgendamento.error = false
        },
        setRequestGetAgendamentoError: (state: State) => {
            state.requestGetAgendamento.loading = false
            state.requestGetAgendamento.error = true
        },
        setRequestGetAgendamentoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetAgendamento.message = action.payload
        },
        resetRequestGetAgendamento: (state: State) => {
            state.requestGetAgendamento = {...responseInitialValues}
        },

        setRequestSaveAgendamentoData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSaveAgendamento.data = action.payload
            state.requestSaveAgendamento.loading = false
            state.requestSaveAgendamento.error = false
        },
        setRequestSaveAgendamentoLoading: (state: State) => {
            state.requestSaveAgendamento.loading = true
            state.requestSaveAgendamento.error = false
        },
        setRequestSaveAgendamentoError: (state: State) => {
            state.requestSaveAgendamento.loading = false
            state.requestSaveAgendamento.error = true
        },
        setRequestSaveAgendamentoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSaveAgendamento.message = action.payload
        },
        resetRequestSaveAgendamento: (state: State) => {
            state.requestSaveAgendamento = {...responseInitialValues}
        },

        setRequestGetTiposVeiculoData: (state: State, action: PayloadAction<ResponsePattern<TipoVeiculo[]>>) => {
            state.requestGetTiposVeiculo.data = action.payload
            state.requestGetTiposVeiculo.loading = false
            state.requestGetTiposVeiculo.error = false
        },
        setRequestGetTiposVeiculoLoading: (state: State) => {
            state.requestGetTiposVeiculo.loading = true
            state.requestGetTiposVeiculo.error = false
        },
        setRequestGetTiposVeiculoError: (state: State) => {
            state.requestGetTiposVeiculo.loading = false
            state.requestGetTiposVeiculo.error = true
        },
        setRequestGetTiposVeiculoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetTiposVeiculo.message = action.payload
        },
        resetRequestGetTiposVeiculo: (state: State) => {
            state.requestGetTiposVeiculo = {...responseInitialValues}
        },

        setRequestGetClientesData: (state: State, action: PayloadAction<ResponsePattern<Cliente[]>>) => {
            state.requestGetClientes.data = action.payload
            state.requestGetClientes.loading = false
            state.requestGetClientes.error = false
        },
        setRequestGetClientesLoading: (state: State) => {
            state.requestGetClientes.loading = true
            state.requestGetClientes.error = false
        },
        setRequestGetClientesError: (state: State) => {
            state.requestGetClientes.loading = false
            state.requestGetClientes.error = true
        },
        setRequestGetClientesMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetClientes.message = action.payload
        },
        resetRequestGetClientes: (state: State) => {
            state.requestGetClientes = {...responseInitialValues}
        },

        setRequestCreateAgendamentoData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestCreateAgendamento.data = action.payload
            state.requestCreateAgendamento.loading = false
            state.requestCreateAgendamento.error = false
        },
        setRequestCreateAgendamentoLoading: (state: State) => {
            state.requestCreateAgendamento.loading = true
            state.requestCreateAgendamento.error = false
        },
        setRequestCreateAgendamentoError: (state: State) => {
            state.requestCreateAgendamento.loading = false
            state.requestCreateAgendamento.error = true
        },
        setRequestCreateAgendamentoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestCreateAgendamento.message = action.payload
        },
        resetRequestCreateAgendamento: (state: State) => {
            state.requestCreateAgendamento = {...responseInitialValues}
        },

        setrequestGetMotoristasData: (state: State, action: PayloadAction<ResponsePattern<Motorista[]>>) => {
            state.requestGetMotoristas.data = action.payload
            state.requestGetMotoristas.loading = false
            state.requestGetMotoristas.error = false
        },
        setrequestGetMotoristasLoading: (state: State) => {
            state.requestGetMotoristas.loading = true
            state.requestGetMotoristas.error = false
        },
        setrequestGetMotoristasError: (state: State) => {
            state.requestGetMotoristas.loading = false
            state.requestGetMotoristas.error = true
        },
        setrequestGetMotoristasMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetMotoristas.message = action.payload
        },
        resetrequestGetMotoristas: (state: State) => {
            state.requestGetMotoristas = {...responseInitialValues}
        },

        setRequestGetNotasFiscaisData: (state: State, action: PayloadAction<ResponsePattern<NotaFiscal[]>>) => {
            state.requestGetNotasFiscais.data = action.payload
            state.requestGetNotasFiscais.loading = false
            state.requestGetNotasFiscais.error = false
        },
        setRequestGetNotasFiscaisLoading: (state: State) => {
            state.requestGetNotasFiscais.loading = true
            state.requestGetNotasFiscais.error = false
        },
        setRequestGetNotasFiscaisError: (state: State) => {
            state.requestGetNotasFiscais.loading = false
            state.requestGetNotasFiscais.error = true
        },
        setRequestGetNotasFiscaisMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetNotasFiscais.message = action.payload
        },
        resetRequestGetNotasFiscais: (state: State) => {
            state.requestGetNotasFiscais = {...responseInitialValues}
        },

        setRequestSaveNotaFiscalData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSaveNotaFiscal.data = action.payload
            state.requestSaveNotaFiscal.loading = false
            state.requestSaveNotaFiscal.error = false
        },
        setRequestSaveNotaFiscalLoading: (state: State) => {
            state.requestSaveNotaFiscal.loading = true
            state.requestSaveNotaFiscal.error = false
        },
        setRequestSaveNotaFiscalError: (state: State) => {
            state.requestSaveNotaFiscal.loading = false
            state.requestSaveNotaFiscal.error = true
        },
        setRequestSaveNotaFiscalMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSaveNotaFiscal.message = action.payload
        },
        resetRequestSaveNotaFiscal: (state: State) => {
            state.requestSaveNotaFiscal = {...responseInitialValues}
        },

        setRequestRemoveNotaFiscalData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestRemoveNotaFiscal.data = action.payload
            state.requestRemoveNotaFiscal.loading = false
            state.requestRemoveNotaFiscal.error = false
        },
        setRequestRemoveNotaFiscalLoading: (state: State) => {
            state.requestRemoveNotaFiscal.loading = true
            state.requestRemoveNotaFiscal.error = false
        },
        setRequestRemoveNotaFiscalError: (state: State) => {
            state.requestRemoveNotaFiscal.loading = false
            state.requestRemoveNotaFiscal.error = true
        },
        setRequestRemoveNotaFiscalMessage: (state: State, action: PayloadAction<string>) => {
            state.requestRemoveNotaFiscal.message = action.payload
        },
        resetRequestRemoveNotaFiscal: (state: State) => {
            state.requestRemoveNotaFiscal = {...responseInitialValues}
        },

        setRequestSaveAgendamentoHorarioData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSaveAgendamentoHorario.data = action.payload
            state.requestSaveAgendamentoHorario.loading = false
            state.requestSaveAgendamentoHorario.error = false
        },
        setRequestSaveAgendamentoHorarioLoading: (state: State) => {
            state.requestSaveAgendamentoHorario.loading = true
            state.requestSaveAgendamentoHorario.error = false
        },
        setRequestSaveAgendamentoHorarioError: (state: State) => {
            state.requestSaveAgendamentoHorario.loading = false
            state.requestSaveAgendamentoHorario.error = true
        },
        setRequestSaveAgendamentoHorarioMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSaveAgendamentoHorario.message = action.payload
        },
        resetRequestSaveAgendamentoHorario: (state: State) => {
            state.requestSaveAgendamentoHorario = {...responseInitialValues}
        },

        setRequestRemoveAgendamentoData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestRemoveAgendamento.data = action.payload
            state.requestRemoveAgendamento.loading = false
            state.requestRemoveAgendamento.error = false
        },
        setRequestRemoveAgendamentoLoading: (state: State) => {
            state.requestRemoveAgendamento.loading = true
            state.requestRemoveAgendamento.error = false
        },
        setRequestRemoveAgendamentoError: (state: State) => {
            state.requestRemoveAgendamento.loading = false
            state.requestRemoveAgendamento.error = true
        },
        setRequestRemoveAgendamentoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestRemoveAgendamento.message = action.payload
        },
        resetRequestRemoveAgendamento: (state: State) => {
            state.requestRemoveAgendamento = {...responseInitialValues}
        },

        setRequestEditAgendamentoData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestEditAgendamento.data = action.payload
            state.requestEditAgendamento.loading = false
            state.requestEditAgendamento.error = false
        },
        setRequestEditAgendamentoLoading: (state: State) => {
            state.requestEditAgendamento.loading = true
            state.requestEditAgendamento.error = false
        },
        setRequestEditAgendamentoError: (state: State) => {
            state.requestEditAgendamento.loading = false
            state.requestEditAgendamento.error = true
        },
        setRequestEditAgendamentoMessage: (state: State, action: PayloadAction<string>) => {
            state.requestEditAgendamento.message = action.payload
        },
        resetRequestEditAgendamento: (state: State) => {
            state.requestEditAgendamento = {...responseInitialValues}
        },

        setRequestSetAgendamentoDesbloqueioData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestSetAgendamentoDebloqueio.data = action.payload
            state.requestSetAgendamentoDebloqueio.loading = false
            state.requestSetAgendamentoDebloqueio.error = false
        },
        setRequestSetAgendamentoDesbloqueioLoading: (state: State) => {
            state.requestSetAgendamentoDebloqueio.loading = true
            state.requestSetAgendamentoDebloqueio.error = false
        },
        setRequestSetAgendamentoDesbloqueioError: (state: State) => {
            state.requestSetAgendamentoDebloqueio.loading = false
            state.requestSetAgendamentoDebloqueio.error = true
        },
        setRequestSetAgendamentoDesbloqueioMessage: (state: State, action: PayloadAction<string>) => {
            state.requestSetAgendamentoDebloqueio.message = action.payload
        },
        resetRequestSetAgendamentoDesbloqueio: (state: State) => {
            state.requestSetAgendamentoDebloqueio = {...responseInitialValues}
        },

        setRequestGetNotasFiscaisCnpjData: (state: State, action: PayloadAction<ResponsePattern<NotaFiscalCnpj[]>>) => {
            state.requestGetNotasFiscaisCnpj.data = action.payload
            state.requestGetNotasFiscaisCnpj.loading = false
            state.requestGetNotasFiscaisCnpj.error = false
        },
        setRequestGetNotasFiscaisCnpjLoading: (state: State) => {
            state.requestGetNotasFiscaisCnpj.loading = true
            state.requestGetNotasFiscaisCnpj.error = false
        },
        setRequestGetNotasFiscaisCnpjError: (state: State) => {
            state.requestGetNotasFiscaisCnpj.loading = false
            state.requestGetNotasFiscaisCnpj.error = true
        },
        setRequestGetNotasFiscaisCnpjMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetNotasFiscaisCnpj.message = action.payload
        },
        resetRequestGetNotasFiscaisCnpj: (state: State) => {
            state.requestGetNotasFiscaisCnpj = {...responseInitialValues}
        },

        setRequestGetNotasFiscaisNrData: (state: State, action: PayloadAction<ResponsePattern<NotaFiscalCnpj[]>>) => {
            state.requestGetNotasFiscaisNr.data = action.payload
            state.requestGetNotasFiscaisNr.loading = false
            state.requestGetNotasFiscaisNr.error = false
        },
        setRequestGetNotasFiscaisNrLoading: (state: State) => {
            state.requestGetNotasFiscaisNr.loading = true
            state.requestGetNotasFiscaisNr.error = false
        },
        setRequestGetNotasFiscaisNrError: (state: State) => {
            state.requestGetNotasFiscaisNr.loading = false
            state.requestGetNotasFiscaisNr.error = true
        },
        setRequestGetNotasFiscaisNrMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGetNotasFiscaisNr.message = action.payload
        },
        resetRequestGetNotasFiscaisNr: (state: State) => {
            state.requestGetNotasFiscaisNr = {...responseInitialValues}
        },

        setRequestVincularAgendamentoNotaFiscalData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestVincularAgendamentoNotaFiscal.data = action.payload
            state.requestVincularAgendamentoNotaFiscal.loading = false
            state.requestVincularAgendamentoNotaFiscal.error = false
        },
        setRequestVincularAgendamentoNotaFiscalLoading: (state: State) => {
            state.requestVincularAgendamentoNotaFiscal.loading = true
            state.requestVincularAgendamentoNotaFiscal.error = false
        },
        setRequestVincularAgendamentoNotaFiscalError: (state: State) => {
            state.requestVincularAgendamentoNotaFiscal.loading = false
            state.requestVincularAgendamentoNotaFiscal.error = true
        },
        setRequestVincularAgendamentoNotaFiscalMessage: (state: State, action: PayloadAction<string>) => {
            state.requestVincularAgendamentoNotaFiscal.message = action.payload
        },
        resetRequestVincularAgendamentoNotaFiscal: (state: State) => {
            state.requestVincularAgendamentoNotaFiscal = {...responseInitialValues}
        },

        setRequestGerarDanfeData: (state: State, action: PayloadAction<ResponsePattern<any>>) => {
            state.requestGerarDanfe.data = action.payload
            state.requestGerarDanfe.loading = false
            state.requestGerarDanfe.error = false
        },
        setRequestGerarDanfeLoading: (state: State) => {
            state.requestGerarDanfe.loading = true
            state.requestGerarDanfe.error = false
        },
        setRequestGerarDanfeError: (state: State) => {
            state.requestGerarDanfe.loading = false
            state.requestGerarDanfe.error = true
        },
        setRequestGerarDanfeMessage: (state: State, action: PayloadAction<string>) => {
            state.requestGerarDanfe.message = action.payload
        },
        resetRequestGerarDanfe: (state: State) => {
            state.requestGerarDanfe = {...responseInitialValues}
        },
    }
})

const { actions } = requestAgendamentoSlice

export const requestGetAgendamentosReducers = {
    data: actions.setRequestGetAgendamentosData,
    loading: actions.setRequestGetAgendamentosLoading,
    error: actions.setRequestGetAgendamentosError,
    message: actions.setRequestGetAgendamentosMessage,
    reset: actions.resetRequestGetAgendamentos,
}

export const requestGetAgendamentosBloqueadosReducers = {
    data: actions.setRequestGetAgendamentosBloqueadosData,
    loading: actions.setRequestGetAgendamentosBloqueadosLoading,
    error: actions.setRequestGetAgendamentosBloqueadosError,
    message: actions.setRequestGetAgendamentosBloqueadosMessage,
    reset: actions.resetRequestGetAgendamentosBloqueados,
}

export const requestGetAgendamentosListaReducers = {
    data: actions.setRequestGetAgendamentosListaData,
    loading: actions.setRequestGetAgendamentosListaLoading,
    error: actions.setRequestGetAgendamentosListaError,
    message: actions.setRequestGetAgendamentosListaMessage,
    reset: actions.resetRequestGetAgendamentosLista,
}

export const requestGetAgendamentoListaReducers = {
    data: actions.setRequestGetAgendamentoListaData,
    loading: actions.setRequestGetAgendamentoListaLoading,
    error: actions.setRequestGetAgendamentoListaError,
    message: actions.setRequestGetAgendamentoListaMessage,
    reset: actions.resetRequestGetAgendamentoLista,
}

export const requestGetAgendamentoReducers = {
    data: actions.setRequestGetAgendamentoData,
    loading: actions.setRequestGetAgendamentoLoading,
    error: actions.setRequestGetAgendamentoError,
    message: actions.setRequestGetAgendamentoMessage,
    reset: actions.resetRequestGetAgendamento,
}

export const requestSaveAgendamentoReducers = {
    data: actions.setRequestSaveAgendamentoData,
    loading: actions.setRequestSaveAgendamentoLoading,
    error: actions.setRequestSaveAgendamentoError,
    message: actions.setRequestSaveAgendamentoMessage,
    reset: actions.resetRequestSaveAgendamento,
}

export const requestGetTiposVeiculoReducers = {
    data: actions.setRequestGetTiposVeiculoData,
    loading: actions.setRequestGetTiposVeiculoLoading,
    error: actions.setRequestGetTiposVeiculoError,
    message: actions.setRequestGetTiposVeiculoMessage,
    reset: actions.resetRequestGetTiposVeiculo,
}

export const requestGetClientesReducers = {
    data: actions.setRequestGetClientesData,
    loading: actions.setRequestGetClientesLoading,
    error: actions.setRequestGetClientesError,
    message: actions.setRequestGetClientesMessage,
    reset: actions.resetRequestGetClientes,
}

export const requestCreateAgendamentoReducers = {
    data: actions.setRequestCreateAgendamentoData,
    loading: actions.setRequestCreateAgendamentoLoading,
    error: actions.setRequestCreateAgendamentoError,
    message: actions.setRequestCreateAgendamentoMessage,
    reset: actions.resetRequestCreateAgendamento,
}

export const requestGetMotoristasReducers = {
    data: actions.setrequestGetMotoristasData,
    loading: actions.setrequestGetMotoristasLoading,
    error: actions.setrequestGetMotoristasError,
    message: actions.setrequestGetMotoristasMessage,
    reset: actions.resetrequestGetMotoristas,
}

export const requestGetNotasFiscaisReducers = {
    data: actions.setRequestGetNotasFiscaisData,
    loading: actions.setRequestGetNotasFiscaisLoading,
    error: actions.setRequestGetNotasFiscaisError,
    message: actions.setRequestGetNotasFiscaisMessage,
    reset: actions.resetRequestGetNotasFiscais,
}

export const requestSaveNotaFiscalReducers = {
    data: actions.setRequestSaveNotaFiscalData,
    loading: actions.setRequestSaveNotaFiscalLoading,
    error: actions.setRequestSaveNotaFiscalError,
    message: actions.setRequestSaveNotaFiscalMessage,
    reset: actions.resetRequestSaveNotaFiscal,
}

export const requestRemoveNotaFiscalReducers = {
    data: actions.setRequestRemoveNotaFiscalData,
    loading: actions.setRequestRemoveNotaFiscalLoading,
    error: actions.setRequestRemoveNotaFiscalError,
    message: actions.setRequestRemoveNotaFiscalMessage,
    reset: actions.resetRequestRemoveNotaFiscal,
}

export const requestSaveAgendamentoHorarioReducers = {
    data: actions.setRequestSaveAgendamentoHorarioData,
    loading: actions.setRequestSaveAgendamentoHorarioLoading,
    error: actions.setRequestSaveAgendamentoHorarioError,
    message: actions.setRequestSaveAgendamentoHorarioMessage,
    reset: actions.resetRequestSaveAgendamentoHorario,
}

export const requestRemoveAgendamentoReducers = {
    data: actions.setRequestRemoveAgendamentoData,
    loading: actions.setRequestRemoveAgendamentoLoading,
    error: actions.setRequestRemoveAgendamentoError,
    message: actions.setRequestRemoveAgendamentoMessage,
    reset: actions.resetRequestRemoveAgendamento,
}

export const requestEditAgendamentoReducers = {
    data: actions.setRequestEditAgendamentoData,
    loading: actions.setRequestEditAgendamentoLoading,
    error: actions.setRequestEditAgendamentoError,
    message: actions.setRequestEditAgendamentoMessage,
    reset: actions.resetRequestEditAgendamento,
}

export const requestSetAgendamentoDesbloqueioReducers = {
    data: actions.setRequestSetAgendamentoDesbloqueioData,
    loading: actions.setRequestSetAgendamentoDesbloqueioLoading,
    error: actions.setRequestSetAgendamentoDesbloqueioError,
    message: actions.setRequestSetAgendamentoDesbloqueioMessage,
    reset: actions.resetRequestSetAgendamentoDesbloqueio,
}

export const requestGetNotasFiscaisCnpjReducers = {
    data: actions.setRequestGetNotasFiscaisCnpjData,
    loading: actions.setRequestGetNotasFiscaisCnpjLoading,
    error: actions.setRequestGetNotasFiscaisCnpjError,
    message: actions.setRequestGetNotasFiscaisCnpjMessage,
    reset: actions.resetRequestGetNotasFiscaisCnpj,
}

export const requestGetNotasFiscaisNrReducers = {
    data: actions.setRequestGetNotasFiscaisNrData,
    loading: actions.setRequestGetNotasFiscaisNrLoading,
    error: actions.setRequestGetNotasFiscaisNrError,
    message: actions.setRequestGetNotasFiscaisNrMessage,
    reset: actions.resetRequestGetNotasFiscaisNr,
}

export const requestVincularAgendamentoNotaFiscalReducers = {
    data: actions.setRequestVincularAgendamentoNotaFiscalData,
    loading: actions.setRequestVincularAgendamentoNotaFiscalLoading,
    error: actions.setRequestVincularAgendamentoNotaFiscalError,
    message: actions.setRequestVincularAgendamentoNotaFiscalMessage,
    reset: actions.resetRequestVincularAgendamentoNotaFiscal,
}

export const requestGerarDanfeReducers = {
    data: actions.setRequestGerarDanfeData,
    loading: actions.setRequestGerarDanfeLoading,
    error: actions.setRequestGerarDanfeError,
    message: actions.setRequestGerarDanfeMessage,
    reset: actions.resetRequestGerarDanfe,
}

export default requestAgendamentoSlice.reducer