import React from 'react'

const ContaGruposAcesso: React.FC = () => {

    return(

        <iframe
            src = "http://app.integralogis.com.br/#/contas/grupos-acesso"
            title = "Grupos de acesso"
            width = "100%"
            height = "100%"
        />

    )

}

export default ContaGruposAcesso