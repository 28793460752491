import React, { useEffect, useState } from 'react'
import { mdiDelete, mdiPencil, mdiPlus } from '@mdi/js'
import Button from '@components/Button'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import ModalConfirm from '@components/Feedback/ModalConfirm'
import { ListColumn, ListOption, ListPaginator, ListRow } from '@components/List'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import ModalCreate from './components/ModalCreate'
import ModalUpdate from './components/ModalUpdate'
import { getMotoristas, deleteMotorista } from '@modules/Cadastros/Motorista/controllers'
import { setCurrentMotorista } from '@modules/Cadastros/Motorista/reducers/motoristaReducer'


const Motoristas: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { Motoristas } = useAppSelector(s => s.motoristas)
    const { requestGetMotoristas } = useAppSelector(s => s.requestMotorista)

    const [openModalCreate, setOpenModalCreate] = useState(false)
    const [openModalUpdate, setOpenModalUpdate] = useState(false)
    const [openModalDelete, setOpenModalDelete] = useState(false)

    const CR = conditionaRender(requestGetMotoristas, Motoristas, true)
    const grid = "1fr 1fr 1fr 100px 100px 70px"

    useEffect(() => {
        getMotoristas(dispatch, userData!)
    }, [dispatch, userData])

    return(

        <>
            <Section>
                <SectionBox
                    title = "Clientes"
                    padding = {false}
                    right = {
                        <Button
                            icon = {mdiPlus}
                            label = "Adicionar motorista"
                            onClick = {() => setOpenModalCreate(true)}
                        />
                    }
                >
                    <ListPaginator
                        data = {Motoristas}
                        CR = {CR}
                        header = {
                            <ListRow grid = {grid}>
                                {['Nome', 'CPF', 'RG', 'CNH','Opções'].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                            </ListRow>
                        }
                        renderItem = {(item, index) => (
                            <ListRow key = {index} grid = {grid}>
                                <ListColumn>{item.nome}</ListColumn>
                                <ListColumn>{item.cpf}</ListColumn>
                                <ListColumn>{item.rg}</ListColumn>
                                <ListColumn>{item.nroCNH}</ListColumn>

                                <ListColumn>
                                    <ListOption
                                        icon = {mdiDelete}
                                        status = "error"
                                        onClick = {() => {
                                            dispatch(setCurrentMotorista(item))
                                            setOpenModalDelete(true)
                                        }}
                                    />
                                    <ListOption
                                        icon = {mdiPencil}
                                        onClick = {() => {
                                            dispatch(setCurrentMotorista(item))
                                            setOpenModalUpdate(true)
                                        }}
                                    />
                                </ListColumn>

                                <ModalConfirm
                                    open = {openModalDelete}
                                    setOpen = {setOpenModalDelete}
                                    title = "Excluir Contratante"
                                    message = "Você tem certeza que deseja excluir este contratante?"
                                    onConfirm = {() => {
                                        deleteMotorista(dispatch, { idUsuarioExclusao: userData!.id, id: item.id }, userData!)
                                    }}
                                />
                            </ListRow>
                        )}
                    />
                </SectionBox>
            </Section>
            <ModalCreate open = {openModalCreate} setOpen = {setOpenModalCreate} />
            <ModalUpdate open = {openModalUpdate} setOpen = {setOpenModalUpdate} />
        </>

    )

}

export default Motoristas