import * as yup from 'yup'

export const createContratanteFormValues = {
    nome: '',
    rg:'',
    nroCNH: '',
    cpf: ''
}

export const createContratanteFormSchema = yup.object().shape({
    nome: yup.string().required('Campo obrigatório'),
    cpf: yup.string().required('Campo obrigatório'),
})
