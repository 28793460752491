import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import { useAppDispatch } from '@redux/hooks'
import ListSolicitacoes from './components/ListSolicitacoes'
import { getAcessoSolicitacoes } from '@modules/acesso/controllers'
import ModalSolicitacao from './components/ModalSolicitacao'
import ModalSaveSolicitacao from './components/ModalSaveSolicitacao'

const AcessoSolicitacaoLista: React.FC = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        getAcessoSolicitacoes(dispatch)
    }, [dispatch])

    return(

        <>
            <Section>
                <SectionBox
                    title = "Solicitações de acesso"
                    padding = {false}
                >
                    <ListSolicitacoes />
                </SectionBox>
            </Section>
            <ModalSolicitacao />
            <ModalSaveSolicitacao />
        </>

    )

}

export default AcessoSolicitacaoLista