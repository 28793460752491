import React from 'react'
import { Form as FormikForm, Formik } from 'formik'
import Modal from '@components/Modal'
import Form from '@components/Form'
import Button from '@components/Button'
import Select from '@components/Select'
import ModalActions from '@components/Modal/Actions'
import { ModalProps } from '@interfaces/Modal'
import { createAcessoUsuario } from '@modules/acesso/controllers/usuarioController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import createSelectItems from '@utils/createSelectItems'
import { createAcessoUsuariosFormValues, createAcessoUsuariosFormSchema } from './config'

const ModalCreate: React.FC <ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { acessoGrupos } = useAppSelector(s => s.acessoGrupo)
    const { requestCreateUsuario } = useAppSelector(s => s.requestAcessoUsuario)

    return(

        <Modal {...props} title = "Criar usuário">
            <Formik
                initialValues = {createAcessoUsuariosFormValues}
                validationSchema = {createAcessoUsuariosFormSchema}
                onSubmit = {v => createAcessoUsuario(dispatch, userData!, {...v, idUsuarioCadastro: userData!.id}, props.setOpen)}
            >
                {({ setFieldValue, values }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                        <Form.Row columns = {2}>
                                        <Form.Group inputID = "nome" inputName = "nome" label = "Nome" />
                                        <Form.Group inputID = "email" inputName = "email" label = "E-mail" />
                                        
                                    </Form.Row>
                            <Form.Row columns={2} >
                            <Form.Group inputID = "senha" inputName = "senha" label = "Senha" inputType = "password" />
                            <Form.Group inputID = "nroDocumento" inputName = "nroDocumento" label = "Documento"  />
                            </Form.Row>
                            <Form.Row columns = {1}>
                                <Select
                                    type = "outline"
                                    label = "Grupo de Acesso"
                                    field = "idGrupoAcesso"
                                    value = {values.idGrupoAcesso}
                                    setFieldValue = {setFieldValue}
                                    items = {createSelectItems(acessoGrupos, 'nome', 'id')}
                                />
                            </Form.Row>
                            <ModalActions>
                                <Button
                                    type = "reset"
                                    label = "Cancelar"
                                    onClick = {() => props.setOpen(false)}
                                />
                                <Button
                                    type = "submit"
                                    label = "Criar"
                                    loading = {requestCreateUsuario.loading}
                                    status = "success"
                                />
                            </ModalActions>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </Modal>
        
    )

}

export default ModalCreate