import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import FormEdit from './components/FormEdit'
import PainelTravas from './components/PainelTravas'
import { getArmazem } from '@modules/armazem/controllers'
import conditionaRender from '@utils/conditionalRender'
import FormFilter from './components/FormFilter'
import ModalBloquear from './components/ModalBloquear'
import ModalDesbloquear from './components/ModalDesbloquear'
import { requestGetArmazemReducers } from '@modules/armazem/reducers/requestArmazemReducer'
import { resetSelectedArmazens, setArmazemBloqueios } from './reducers/armazenEditarReducer'

const ArmazemEditar: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { armazem } = useAppSelector(s => s.armazem)
    const { requestGetArmazem } = useAppSelector(s => s.requestArmazem)
    const { guidArmazem } = useParams()

    const CR = conditionaRender(requestGetArmazem, armazem as any)

    useEffect(() => {
        if(!!guidArmazem){
            getArmazem(dispatch, guidArmazem, userData!)
        }
    }, [dispatch, guidArmazem, userData])

    useEffect(() => {
        return () => {
            dispatch(requestGetArmazemReducers.reset())
            dispatch(resetSelectedArmazens())
            dispatch(setArmazemBloqueios(null))
        }
    }, [dispatch])

    return(

        <>
            <Section>
                <SectionBox
                    title = {armazem?.descricao ?? 'Armazém'}
                    goBack
                >
                    {CR.DATA && <FormEdit />}
                </SectionBox>
                <SectionBox title = "Travas do agendamento">
                    {CR.DATA && (
                        <>
                            <FormFilter />
                            <PainelTravas />
                        </>
                    )}
                </SectionBox>
            </Section>
            <ModalBloquear />
            <ModalDesbloquear />
        </>

    )

}

export default ArmazemEditar