import React from 'react'
import { Form as FormikForm, Formik } from 'formik'
import Form from '@components/Form'
import Modal from '@components/Modal'
import Button from '@components/Button'
import ModalActions from '@components/Modal/Actions'
import { ModalProps } from '@interfaces/Modal'
import { updateContrante } from '@modules/contratante/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { updateContratanteFormSchema } from './config'
import { updateCliente } from '@modules/Cadastros/Cliente/controllers'

const ModalUpdate: React.FC <ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { currentCliente } = useAppSelector(s => s.clientes)
    const { requestUpdateCliente } = useAppSelector(s => s.requestCliente)

    return(

        <Modal {...props} title = "Editar cliente">
            {!!currentCliente && (
                <Formik
                    initialValues = {{
                        id: currentCliente.id,
                        nome: currentCliente.nome,
                        inscricaoEstadual: currentCliente.inscricaoEstadual,
                        inscricaoMunicipal: currentCliente.inscricaoMunicipal,
                        nroDocumento: currentCliente.nroDocumento,
                        endereco: currentCliente.endereco,
                        bairro: currentCliente.bairro,
                        uf: currentCliente.uf,
                        razaoSocial: currentCliente.razaoSocial,
                        idUsuarioAlteracao: userData!.id
                    }}
                    validationSchema = {updateContratanteFormSchema}
                    onSubmit = {v => updateCliente(dispatch, v, userData!)}
                >
                    {() => (
                        <FormikForm>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {2}>
                                    <Form.Group inputID = "nome" inputName = "nome" label = "Nome" />
                                    <Form.Group inputID = "razaoSocial" inputName = "razaoSocial" label = "razão Social" />
                                </Form.Row>
                                <Form.Row columns = {1}>
                                    <Form.Group inputID = "nroDocumento" inputName = "nroDocumento" label = "Documento" />
                                </Form.Row>
                                <Form.Row columns = {2}>
                                    <Form.Group inputID = "inscricaoEstadual" inputName = "inscricaoEstadual" label = "Inscrição Estadual" />
                                    <Form.Group inputID = "inscricaoMunicipal" inputName = "inscricaoMunicipal" label = "Inscrição Municipal" />
                                </Form.Row>
                                <Form.Row columns = {3}>
                                    <Form.Group inputID = "endereco" inputName = "endereco" label = "Endereço" />
                                    <Form.Group inputID = "bairro" inputName = "bairro" label = "Bairro" />
                                    <Form.Group inputID = "uf" inputName = "uf" label = "UF" />
                                </Form.Row>
                                    
                                <ModalActions>
                                    <Button
                                        type = "reset"
                                        label = "Cancelar"
                                        onClick = {() => props.setOpen(false)}
                                    />
                                    <Button
                                        type = "submit"
                                        label = "Editar"
                                        loading = {requestUpdateCliente.loading}
                                        status = "success"
                                    />
                                </ModalActions>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            )}
        </Modal>

    )

}

export default ModalUpdate