import ENV from "@env/index"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { IMotorista } from "../interfaces/Motorista"

import { requestCreateMotoristaActions, requestDeleteMotoristaActions, requestGetMotoristasActions, requestUpdateMotoristaActions } from "../reducers/requestMotoristaReducer"
import { setMotoristas } from "../reducers/motoristaReducer"
import { UserData } from "@modules/auth/interfaces/UserData"
import { jsonToParams } from "@utils/jsonToParams"
import getContratante from "@modules/auth/scripts/getContratante"

export async function getMotoristas(dispatch: DispatchType, userData: UserData){
    initRequest(dispatch, requestGetMotoristasActions)

    const endpoint = `${ENV.APP_ENDPOINT}/motoristas/${getContratante(userData).uuidContratante}`

    const response = await request.get<ResponsePattern<IMotorista[]>>({ endpoint })

    handleResponse('getMotoristas', dispatch, response, requestGetMotoristasActions)
    .then(data => {

        dispatch(setMotoristas(data))
    })
}

export async function createMotorista<T>(dispatch: DispatchType, data: T, userData: UserData){
    initRequest(dispatch, requestCreateMotoristaActions)

    const body = {
        ...data,
        uuidContratante: getContratante(userData).uuidContratante
    }

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/motorista`
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('createMotoristas', dispatch, response, requestCreateMotoristaActions, options)
    .then(() => {
        getMotoristas(dispatch, userData)
    })
}

export async function updateMotorista<T>(dispatch: DispatchType, data: T, userData: UserData){
    initRequest(dispatch, requestUpdateMotoristaActions)

    const body = {
        ...data,
        uuidContratante: getContratante(userData).uuidContratante
    }

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/motorista`
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('updateMotoristas', dispatch, response, requestUpdateMotoristaActions, options)
    .then(() => {
        getMotoristas(dispatch, userData)
    })
}

export async function deleteMotorista<T>(dispatch: DispatchType, body: T, userData: UserData){
    initRequest(dispatch, requestDeleteMotoristaActions)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/motorista/deletar`
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('deleteMotoristas', dispatch, response, requestDeleteMotoristaActions, options)
    .then(() => {
        getMotoristas(dispatch, userData)
    })
}