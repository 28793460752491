import ENV from "@env/index"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { Contratante } from "../interfaces/Contratante"
import { setContratantes } from "../reducers/contratanteReducer"
import { requestCreateContratanteActions, requestDeleteContratanteActions, requestGetContratantesActions, requestUpdateContratanteActions } from "../reducers/requestContratanteReducer"

export async function getContratantes(dispatch: DispatchType){
    initRequest(dispatch, requestGetContratantesActions)

    const endpoint = `${ENV.APP_ENDPOINT}/contratante`
    const response = await request.get<ResponsePattern<Contratante[]>>({ endpoint })

    handleResponse('getContratantes', dispatch, response, requestGetContratantesActions)
    .then(data => {
        dispatch(setContratantes(data))
    })
}

export async function createContrante<T>(dispatch: DispatchType, body: T){
    initRequest(dispatch, requestCreateContratanteActions)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/contratante`
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('createContrante', dispatch, response, requestCreateContratanteActions, options)
    .then(() => {
        getContratantes(dispatch)
    })
}

export async function updateContrante<T>(dispatch: DispatchType, body: T){
    initRequest(dispatch, requestUpdateContratanteActions)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/contratante`
    const response = await request.put<ResponsePattern<any>>({ endpoint, body })

    handleResponse('updateContrante', dispatch, response, requestUpdateContratanteActions, options)
    .then(() => {
        getContratantes(dispatch)
    })
}

export async function deleteContrante<T>(dispatch: DispatchType, body: T){
    initRequest(dispatch, requestDeleteContratanteActions)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/contratante/deletar`
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('deleteContrante', dispatch, response, requestDeleteContratanteActions, options)
    .then(() => {
        getContratantes(dispatch)
    })
}