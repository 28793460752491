import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form as FormikForm } from 'formik'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import handleLogin from '../../../../scripts/handleLogin'
import formValues from './values'
import ChangeRoute from '../../../../components/ChangeRoute'
import Form from '@components/Form'
import Button from '@components/Button'
import handleContratanteByLink from '@modules/auth/utils/handleContratanteByLink'

const FormLogin: React.FC = () => {

    const dispatch = useAppDispatch()
    const { requestSendAuthLogin } = useAppSelector(s => s.requestAuth)
    const [redirect, setRedirect] = useState(false)

    return(

        <>
            {redirect && <ChangeRoute />}
            <Formik
                initialValues = {{
                    ...formValues(),
                    uuidContratante: handleContratanteByLink()
                }}
                onSubmit = {async values => await handleLogin(dispatch, setRedirect, values)}
            >
                {({ errors }) => (
                    <FormikForm style = {{display: 'flex', flexDirection: 'column'}}>
                        <Form.Container padding = {false}>
                            <div id = "formContainer" className = "light-mode">
                                <div className = "logoContainer">
                                    <span>Login</span>
                                </div>
                                <Form.Row columns = {1}>
                                    <Form.Group
                                        label = "E-mail"
                                        inputID = "email"
                                        inputName = "email"
                                        error = {!!errors.email}
                                    />
                                    <Form.Group
                                        label = "Senha"
                                        inputID = "senha"
                                        inputName = "senha"
                                        inputType = "password"
                                        error = {!!errors.senha}
                                    />
                                </Form.Row>
                                <div>
                                    <Link to = "/solicitacao-acesso">Solicitar acesso</Link>
                                </div>
                                <Button
                                    label = "Login"
                                    type = "submit"
                                    loading = {requestSendAuthLogin.loading}
                                />
                            </div>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormLogin