import React, { useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { Navigate } from 'react-router'
import { mdiClose } from '@mdi/js'
import Form from '@components/Form'
import Select from '@components/Select'
import Button from '@components/Button'
import ButtonGroup from '@components/Button/Group'
import { maskPlaca } from '@constants/masks'
import selectTiposAgendamento from '@constants/select/tiposAgendamento'
import { setCurrentArmazem } from '@modules/agendamento/reducers/agendamentoReducer'
import { createAgendamento, getMotoristas } from '@modules/agendamento/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import createSelectItems from '@utils/createSelectItems'
import findMotorista from '../../scripts/findMotorista'
import { formSchema, formValues } from './config'

const FormCreate: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { armazens } = useAppSelector(s => s.armazem)
    const { clientes, tiposVeiculo, motoristas } = useAppSelector(s => s.agendamento)
    const { requestCreateAgendamento, requestGetMotoristas } = useAppSelector(s => s.requestAgendamento)
    const [redirect, setRedirect] = useState('')

    return(

        <>
            {!!redirect && <Navigate to = {`/agendamento/cadastroFiscal/${redirect}`} />}
            <Formik
                initialValues = {formValues}
                validationSchema = {formSchema}
                onSubmit = {v => createAgendamento(dispatch, v, setRedirect, userData!, findMotorista(motoristas ?? [], v.cpfMotorista))}
            >
                {({ handleSubmit, setFieldValue, handleChange, values, errors }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Row columns = {4}>
                                <Select
                                    type = "outline"
                                    label = "Tipo de agendamento"
                                    value = {values.tipoAgendamento}
                                    field = "tipoAgendamento"
                                    setFieldValue = {setFieldValue}
                                    items = {selectTiposAgendamento}
                                />
                                <Select
                                    type = "outline"
                                    label = "Cliente"
                                    value = {values.cliente}
                                    field = "cliente"
                                    setFieldValue = {setFieldValue}
                                    items = {createSelectItems(clientes, 'nome', 'nome')}
                                />
                                <Form.Group
                                    label = "Peso (KG)"
                                    error = {!!errors.peso}
                                    inputID = "peso"
                                    inputName = "peso"
                                    inputType = "number"
                                />
                                <Select
                                    type = "outline"
                                    label = "Armazém"
                                    value = {values.armazem}
                                    field = "armazem"
                                    setFieldValue = {setFieldValue}
                                    setValue = {v => dispatch(setCurrentArmazem(v))}
                                    items = {createSelectItems(armazens, 'descricao', 'id')}
                                />
                            </Form.Row>
                            <Form.Row grid = "repeat(2, 1fr) auto" columns = {3}>
                                <Form.Group
                                    label = "Pesquisar por nome"
                                    inputID = "pesquisaNomeMotorista"
                                    inputName = "pesquisaNomeMotorista"
                                    onChange = {() => getMotoristas(dispatch, { nome: values.pesquisaNomeMotorista, documento: ''}, userData!, setFieldValue)}
                                />
                                <Form.Group
                                    label = "Pesquisar por CPF"
                                    inputID = "pesquisaDocMotorista"
                                    inputName = "pesquisaDocMotorista"
                                    inputType = "number"
                                    onChange = {() => getMotoristas(dispatch, { nome: '', documento: values.pesquisaDocMotorista }, userData!, setFieldValue)}
                                />
                                <Button
                                    icon = {mdiClose}
                                    loading = {requestGetMotoristas.loading}
                                    status = {requestGetMotoristas.loading ? "disabled" : "error"}
                                    onClick = {() => {
                                        ['pesquisaNomeMotorista', 'pesquisaDocMotorista', 'cpfMotorista', 'nomeMotorista', 'rgMotorista', 'cnhMotorista']
                                        .forEach(key => setFieldValue(key, ''))
                                    }}
                                />
                                {/* <Select
                                    type = "outline"
                                    label = {`Motoristas (${motoristas?.length ?? 0})`}
                                    placeholder = "Selecionar"
                                    value = {values.selectMotorista}
                                    field = "selectMotorista"
                                    setValue = {id => {
                                        const { documento, nome, rg, nroCNH } = setSelectMotorista(motoristas!, id)

                                        setFieldValue('cpfMotorista', documento)
                                        setFieldValue('nomeMotorista', nome)
                                        setFieldValue('rgMotorista', rg ?? '')
                                        setFieldValue('cnhMotorista', nroCNH ?? '')
                                    }}
                                    setFieldValue = {setFieldValue}
                                    items = {createSelectItems(motoristas, 'nome', 'id')}
                                /> */}
                            </Form.Row>
                            <Form.Row columns = {4}>
                                <Form.Group
                                    label = "CPF motorista"
                                    error = {!!errors.cpfMotorista}
                                    inputID = "cpfMotorista"
                                    inputName = "cpfMotorista"
                                />
                                <Form.Group
                                    label = "Nome motorista"
                                    error = {!!errors.nomeMotorista}
                                    inputID = "nomeMotorista"
                                    inputName = "nomeMotorista"
                                />
                                <Form.Group
                                    label = "RG motorista"
                                    error = {!!errors.rgMotorista}
                                    inputID = "rgMotorista"
                                    inputName = "rgMotorista"
                                    inputType = "number"
                                />
                                <Form.Group
                                    label = "CNH motorista"
                                    error = {!!errors.cnhMotorista}
                                    inputID = "cnhMotorista"
                                    inputName = "cnhMotorista"
                                />
                            </Form.Row>
                            <Form.Row columns = {4}>
                                <Select
                                    type = "outline"
                                    label = "Tipo do veículo"
                                    value = {values.tipoVeiculo}
                                    field = "tipoVeiculo"
                                    setFieldValue = {setFieldValue}
                                    items = {createSelectItems(tiposVeiculo, 'descricao', 'descricao')}
                                />
                                <Form.Group
                                    label = "Placa"
                                    error = {!!errors.placaVeiculo}
                                    inputID = "placaVeiculo"
                                    inputName = "placaVeiculo"
                                    mask = {maskPlaca}
                                    handleChange = {handleChange}
                                />
                                <Form.Group
                                    label = "Placa reboque 1"
                                    inputID = "placaReboque1"
                                    inputName = "placaReboque1"
                                    mask = {maskPlaca}
                                    handleChange = {handleChange}
                                />
                                <Form.Group
                                    label = "Placa reboque 2"
                                    inputID = "placaReboque2"
                                    inputName = "placaReboque2"
                                    mask = {maskPlaca}
                                    handleChange = {handleChange}
                                />
                            </Form.Row>
                            <Form.Row columns = {2}>
                                <Form.Group
                                    label = "Observações"
                                    inputID = "obs"
                                    inputName = "obs"
                                    inputType = "textarea"
                                    inputRows = {3}
                                />
                            </Form.Row>
                            <ButtonGroup>
                                <Button
                                    label = "Salvar agendamento"
                                    status = "success"
                                    loading = {requestCreateAgendamento.loading}
                                    onClick = {handleSubmit}
                                />
                            </ButtonGroup>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormCreate