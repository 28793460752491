import React from 'react'

const ContaUsuarios: React.FC = () => {

    return(

        <iframe
            src = "http://app.integralogis.com.br/#/contas"
            title = "Contas"
            width = "100%"
            height = "100%"
        />

    )

}

export default ContaUsuarios