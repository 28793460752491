import React from 'react'
// import labels from '@constants/labels'
import labels from '@constants/labels'
import status from '@constants/status'
import Icon from '@mdi/react'
import { mdiClockOutline } from '@mdi/js'

const LabelsAgendamento: React.FC = () => {

    return(

        <div id = "labelsContainer">
            <div className = "itensGroup">
                <span className = "w-full mb-2">Legendas do Agendamento</span>
                {labels.map(({ id, label }) => (
                    <div key = {id} className = {`boxLabel label-${id}`}>{label}</div>
                ))}
            </div>
            <div className = "itensGroup">
                <span className = "w-full mb-2">Legendas dos Status do Agendamento</span>
                {status.map(({ id, label }) => (
                    <div key = {id} className = {`boxStatus status-${id}`}>
                        <Icon path = {mdiClockOutline} size = "16" />
                        {label}
                    </div>
                ))}
            </div>
        </div>

    )

}

export default LabelsAgendamento