import { RouteType } from "@routes/interfaces"

const contaRoutes: RouteType[] = [
    {
        key: 'conta',
        path: 'contas',
        title: 'Conta',
        userVerification: true,
        subRoutes: [
            {key: 'contaUsuarios', path: 'usuarios', title: 'Usuários', userVerification: true},
            {key: 'contaUsuariosAcesso', path: 'usuarios-acesso', title: 'Usuários', userVerification: true},
            {key: 'contaGruposAcesso', path: 'grupos-de-acesso', title: 'Grupos de Acesso', userVerification: true},
            {key: 'acessoSolicitacaoLista', path: 'solicitacoes-acesso', title: 'Solicitações de acesso', userVerification: true},
        ]
    }
]

export default contaRoutes