import { UserData } from "../interfaces/UserData"

export default function handleContratanteByLink(user?: UserData){
    const url = window.location.href

    if(url.includes('agriconnection')){
        return '15f9647b-1221-4b9b-b661-c0539c4e814a'
    }
    if(!user || url.includes('comando')){
        return '35f9647b-1221-4b9b-b661-c0539c4e8143'
    }

    return user.contratante.uuid
}