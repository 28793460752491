import ENV from "@env/index"
import { UserData } from "@modules/auth/interfaces/UserData"
import { DispatchType } from "@redux/types"
import request from '@utils/request'
import { ResponsePattern } from '@utils/response/types'
import { handleResponse, initRequest } from '@utils/response'
import { Permissao } from "../interfaces/Permissao"
import { GrupoAcesso } from "../interfaces/GrupoAcesso"
import { AcessoGrupoPermissao } from "../interfaces/AcessoGrupoPermissao"
import * as R from '../reducers/grupo/requestAcessoGrupoReducer'
import { setAcessoGrupoPermissoes, setAcessoGrupos } from '../reducers/grupo/acessoGrupoReducer'
import getContratante from "@modules/auth/scripts/getContratante"

export async function getAcessoGrupos(dispatch: DispatchType, user: UserData){
    initRequest(dispatch, R.requestGetAcessoGrupos)

    const endpoint = `${ENV.APP_ENDPOINT}/grupos-acesso`
    const params = `/${getContratante(user).uuidContratante}`
    const response = await request.get<ResponsePattern<GrupoAcesso[]>>({endpoint, params})

    handleResponse('getAcessoGrupos', dispatch, response, R.requestGetAcessoGrupos)
    .then(data => {
        dispatch(setAcessoGrupos(data))
    })
}

export async function filterGetAcessoGrupos<T>(dispatch: DispatchType, user: UserData, values: T){
    initRequest(dispatch, R.requestGetAcessoGrupos)

    const endpoint = `${ENV.APP_ENDPOINT}/grupos-acesso`
    const body = {
        ...values,
        ...getContratante(user)
    }
    const response = await request.post<ResponsePattern<GrupoAcesso[]>>({endpoint, body})

    handleResponse('getAcessoGrupos', dispatch, response, R.requestGetAcessoGrupos)
    .then(data => {
        dispatch(setAcessoGrupos(data))
    })
}

export async function getAcessoGrupoPermissoes(dispatch: DispatchType, idGrupo: number){
    initRequest(dispatch, R.requestGetAcessoGrupoPermissoes)

    const endpoint = `${ENV.APP_ENDPOINT}/grupo-acesso-permissoes/${idGrupo}`
    const response = await request.get<ResponsePattern<AcessoGrupoPermissao[]>>({endpoint})

    handleResponse('getAcessoGrupoPermissoes', dispatch, response, R.requestGetAcessoGrupoPermissoes)
    .then(data => {
        dispatch(setAcessoGrupoPermissoes(data))
    })
}

export async function removeAcessoGrupo<T>(dispatch: DispatchType, user: UserData, values: T){
    initRequest(dispatch, R.requestRemoveAcessoGrupo)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/grupo-acesso/deletar`
    const body = {
        ...values,
        ...getContratante(user)
    }
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('removeAcessoGrupo', dispatch, response, R.requestRemoveAcessoGrupo, options)
    .then(() => {
        getAcessoGrupos(dispatch, user)
    })
}

export async function addAcessoGrupo<T>(
    dispatch: DispatchType,
    user: UserData,
    values: T,
    setNewGrupo: Function,
    setOpen: Function,
    setRedirect: Function
){
    initRequest(dispatch, R.requestAddAcessoGrupo)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/grupo-acesso/salvar`
    const body = {
        ...values,
        ...getContratante(user)
    }
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('addAcessoGrupo', dispatch, response, R.requestAddAcessoGrupo, options)
    .then(data => {
        setNewGrupo(data.id)
        getAcessoGrupos(dispatch, user)
        setOpen(false)
        setRedirect(true)
    })
}

export async function updateAcessoGrupo<T>(dispatch: DispatchType, user: UserData, values: T, setOpen: Function){
    initRequest(dispatch, R.requestUpdateAcessoGrupo)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/grupo-acesso/editar`
    const body = {
        ...values,
        ...getContratante(user)
    }
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('updateAcessoGrupo', dispatch, response, R.requestUpdateAcessoGrupo, options)
    .then(() => {
        setOpen(false)
        getAcessoGrupos(dispatch, user)
    })
}

export async function saveAcessoGrupo(dispatch: DispatchType, body: Permissao[], reload: () => void){
    initRequest(dispatch, R.requestSaveAcessoGrupo)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/grupo-acesso/editar-configuracoes`
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('saveAcessoGrupo', dispatch, response, R.requestSaveAcessoGrupo, options)
    .then(() => {
        reload()
    })
}

export async function saveAcessoGrupoHome<T>(dispatch: DispatchType, user: UserData, values: T, reload: () => void){
    initRequest(dispatch, R.requestSaveAcessoGrupoHome)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/grupo-acesso/selecionar-home`
    const body = {
        ...values,
        ...getContratante(user)
    }
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('saveAcessoGrupoHome', dispatch, response, R.requestSaveAcessoGrupoHome, options)
    .then(() => {
        reload()
    })
}