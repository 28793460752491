import React from 'react'
import List, { ListColumn, ListMessage, ListOption, ListRow, ListSection } from '@components/List'
import { useAppSelector } from '@redux/hooks'
import { mdiEye } from '@mdi/js'

const ListNotas: React.FC = () => {

    const { agendamento } = useAppSelector(s => s.agendamento)
    const grid = "1fr 1fr 100px 30px"

    const CR = {
        DATA: agendamento!.listaNfs.length > 0,
        NO_DATA: agendamento!.listaNfs.length === 0,
    }

    return(

        <div style = {{marginTop: 24}}>
            <List name = "notas">
                <ListSection type = "header">
                    <ListRow grid = {grid}>
                        {['Nº Nota', 'Série', 'Extensão', 'Link'].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection>
                    {CR.NO_DATA && <ListMessage text = "Não há notas cadastradas!" />}
                    {CR.DATA && agendamento!.listaNfs.map((item, index) => (
                        <ListRow
                            key = {index}
                            grid = {grid}
                        >
                            <ListColumn>{item.nroNota}</ListColumn>
                            <ListColumn>{item.nroSerie}</ListColumn>
                            <ListColumn>{item.extensao}</ListColumn>
                            <ListColumn>
                                <ListOption
                                    icon = {mdiEye}
                                    target = "_blank"
                                    href = {true}
                                    link = {item.caminhoArquivo}
                                />
                            </ListColumn>
                        </ListRow>
                        ))}
                </ListSection>
            </List>
        </div>

    )

}

export default ListNotas