import ENV from "@env/index"
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { handleResponse, initRequest } from "@utils/response"
import { ResponsePattern } from "@utils/response/types"
import { Modulo } from "../interfaces/Modulo"
import { setModulos } from "../reducers/modulo/moduloReducer"
import { requestAddModuloActions, requestGetModuloActions, requestRemoveModuloActions, requestUpdateModuloActions } from "../reducers/modulo/requestModuloReducer"

export async function getModulos(dispatch: DispatchType){
    initRequest(dispatch, requestGetModuloActions)

    const endpoint = `${ENV.APP_ENDPOINT}/modulo`
    const response = await request.get<ResponsePattern<Modulo[]>>({ endpoint })

    handleResponse('getModulo', dispatch, response, requestGetModuloActions)
    .then(data => {
        dispatch(setModulos(data))
    })
}

export async function addModulo<T>(dispatch: DispatchType, body: T, setOpen: Function){
    initRequest(dispatch, requestAddModuloActions)

    const endpoint = `${ENV.APP_ENDPOINT}/modulo/salvar`
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('addModulo', dispatch, response, requestAddModuloActions)
    .then(() => {
        getModulos(dispatch)
        setOpen()
    })
}

export async function updateModulo<T>(dispatch: DispatchType, body: T, setOpen: Function){
    initRequest(dispatch, requestUpdateModuloActions)

    const endpoint = `${ENV.APP_ENDPOINT}/modulo/editar`
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('updateModulo', dispatch, response, requestUpdateModuloActions)
    .then(() => {
        getModulos(dispatch)
        setOpen()
    })
}

export async function removeModulo<T>(dispatch: DispatchType, body: T){
    initRequest(dispatch, requestRemoveModuloActions)

    const endpoint = `${ENV.APP_ENDPOINT}/modulo/deletar`
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('removeModulo', dispatch, response, requestRemoveModuloActions)
    .then(() => {
        getModulos(dispatch)
    })
}