import React from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import minutesInterval from '@utils/minutesInterval'
import numberedArray from '@utils/numberedArray'
import { resetSelectedArmazens, setBloqueioValues, setOpenModalBloquear, setOpenModalDesbloquear } from '../../reducers/armazenEditarReducer'
import Button from '@components/Button'
import PaintelItem from '../PaintelItem'

const PainelTravas: React.FC = () => {

    const dispatch = useAppDispatch()
    const { armazem } = useAppSelector(s => s.armazem)
    const { armazemBloqueios, selectedArmazens } = useAppSelector(s => s.armazemEditar)

    return(

        <>
            {(!!armazemBloqueios && !!armazem) && (
                <>
                    <div className = "w-full flex flex-row justify-between mt-6 gap-4">
                        <div className = "flex flex-row gap-4">
                            <Button
                                label = "Bloquear tudo"
                                status = "error"
                                onClick = {() => {
                                    dispatch(setBloqueioValues(null))
                                    dispatch(setOpenModalBloquear(true))
                                }}
                            />
                            <Button
                                label = "Desbloquear tudo"
                                status = "success"
                                onClick = {() => {
                                    dispatch(setBloqueioValues(null))
                                    dispatch(setOpenModalDesbloquear(true))
                                }}
                            />
                        </div>
                        {selectedArmazens.length > 0 && (
                            <div className = "flex flex-row items-center gap-4">
                                <h1>{`Selecionados (${selectedArmazens.length})`}</h1>
                                <Button
                                    label = "Bloquear selecionados"
                                    status = "error"
                                    onClick = {() => {
                                        dispatch(setOpenModalBloquear(true))
                                    }}
                                />
                                <Button
                                    label = "Desbloquear selecionados"
                                    status = "success"
                                    onClick = {() => {
                                        dispatch(setOpenModalDesbloquear(true))
                                    }}
                                />
                                <Button
                                    label = "Limpar"
                                    status = "error"
                                    onClick = {() => {
                                        dispatch(resetSelectedArmazens())
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className = "tableContainer custom scroll mt-6">
                        <table className = "w-full custom">
                            <thead className = "flex">
                                <tr className = "flex w-full">
                                    <th className = "w-[80px]">Hora</th>
                                    {numberedArray(armazem.qtdeDocas, false).map((dock, index) => (
                                        <th key = {index} className = "flex-1 min-w-[246px]">{`Janela ${dock}`}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className = "flex flex-col items-center justify-between overflow-scroll w-full max-h-[50vh] overlayScroll">
                                {minutesInterval(armazem.intervalo === '01:00' ? 60 : 30).map((hour, index) => (
                                    <tr key = {index} className = "flex w-full">
                                        <td className = "w-[80px] flex items-center justify-center text-center">{hour}</td>
                                        {numberedArray(armazem.qtdeDocas, false).map((dock, dockIndex) => (
                                            <PaintelItem
                                                key = {dockIndex}
                                                hour = {hour}
                                                dock = {dock}
                                            />
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </>

    )

}

export default PainelTravas