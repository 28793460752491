import { RoutePageType } from "./interfaces"
import agendamentoPages from "@modules/agendamento/pages"
import authPages from "@modules/auth/pages"
import armazemPages from "@modules/armazem/pages"
import relatorioPages from "@modules/relatorio/pages"
import contaPages from "@modules/conta/pages"
import acessoPages from "@modules/acesso/pages"
import suportePages from "@modules/suporte/pages"
import modulosPages from "@modules/modulos/pages"
import contratantePages from "@modules/contratante/pages"
import clientePages from "@modules/Cadastros/Cliente/pages"
import motoristaPages from "@modules/Cadastros/Motorista/pages"

const routesPages: RoutePageType[] = [
    ...authPages,
    ...agendamentoPages,
    ...armazemPages,
    ...relatorioPages,
    ...contaPages,
    ...acessoPages,
    ...suportePages,
    ...modulosPages,
    ...contratantePages,
    ...clientePages,
    ...motoristaPages
]

export { routesPages }