import React from 'react'
import { useAppSelector } from '@redux/hooks'
import numberedArray from '@utils/numberedArray'
import minutesInterval from '@utils/minutesInterval'
import PainelItem from '../PainelItem'

const Painel: React.FC = () => {

    const { armazens } = useAppSelector(s => s.armazem)
    const { currentAgendamentoLista } = useAppSelector(s => s.agendamento)

    const { id } = armazens!.find(f => f.id === currentAgendamentoLista?.idArmazem)!

    return(

        <>
            {(!!armazens) && (
                <div className = "tableContainer custom scroll mt-6">
                    <table className = "w-full custom">
                        <thead className = "flex">
                            <tr className = "flex w-full">
                                <th className = "w-[80px]">Hora</th>
                                {numberedArray(armazens.find(f => f.id === id)!.qtdeDocas, false).map((dock, index) => (
                                    <th key = {index} className = "flex-1 min-w-[246px]">{`Janela ${dock}`}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className = "flex flex-col items-center justify-between overflow-scroll w-full max-h-[50vh] overlayScroll">
                            {minutesInterval(armazens.find(f => f.id === id)!.intervalo === '01:00' ? 60 : 30).map((hour, index) => (
                                <tr key = {index} className = "flex w-full">
                                    <td className = "w-[80px] flex items-center justify-center text-center">{hour}</td>
                                    {numberedArray(armazens.find(f => f.id === id)!.qtdeDocas, false).map((dock, dockIndex) => (
                                        <PainelItem
                                            key = {dockIndex}
                                            hour = {hour}
                                            dock = {dock}
                                        />
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>

    )

}

export default Painel