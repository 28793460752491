import ENV from '@env/index'
import { DispatchType } from '@redux/types'
import request from '@utils/request'
import { ResponsePattern } from '@utils/response/types'
import { handleResponse, initRequest } from '@utils/response'
import { AcessoModulo } from '../interfaces/AcessoModulo'
import * as R from '../reducers/modulo/requestAcessoModuloReducer'
import { setAcessoModulos } from '../reducers/modulo/acessoModuloReducer'

export async function getAcessoModulos(dispatch: DispatchType){
    initRequest(dispatch, R.requestGetAcessoModulo)

    const endpoint = `${ENV.APP_ENDPOINT}/modulo`
    const response = await request.get<ResponsePattern<AcessoModulo[]>>({ endpoint })

    handleResponse('getAcessoModulo', dispatch, response, R.requestGetAcessoModulo)
    .then(data => {
        dispatch(setAcessoModulos(data))
    })
}

export async function addAcessoModulo<T>(dispatch: DispatchType, body: T, setOpen: Function){
    initRequest(dispatch, R.requestAddAcessoModulo)

    const endpoint = `${ENV.APP_ENDPOINT}/modulo/salvar`
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('addAcessoModulo', dispatch, response, R.requestAddAcessoModulo)
    .then(() => {
        getAcessoModulos(dispatch)
        setOpen()
    })
}

export async function updateAcessoModulo<T>(dispatch: DispatchType, body: T, setOpen: Function){
    initRequest(dispatch, R.requestUpdateAcessoModulo)

    const endpoint = `${ENV.APP_ENDPOINT}/modulo/editar`
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('updateAcessoModulo', dispatch, response, R.requestUpdateAcessoModulo)
    .then(() => {
        getAcessoModulos(dispatch)
        setOpen()
    })
}

export async function removeAcessoModulo<T>(dispatch: DispatchType, body: T){
    initRequest(dispatch, R.requestRemoveAcessoModulo)

    const endpoint = `${ENV.APP_ENDPOINT}/modulo/deletar`
    const response = await request.post<ResponsePattern<any>>({endpoint, body})

    handleResponse('removeAcessoModulo', dispatch, response, R.requestRemoveAcessoModulo)
    .then(() => {
        getAcessoModulos(dispatch)
    })
}