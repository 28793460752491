import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IMotorista } from "../interfaces/Motorista"

interface State {
    Motoristas: IMotorista[] | null
    currentMotorista: IMotorista | null
}

const initialState: State = {
    Motoristas: null,
    currentMotorista: null,
}

const MotoristaSlice = createSlice({
    name: 'Motorista',
    initialState,
    reducers: {
        setMotoristas: (state, action: PayloadAction<IMotorista[] | null>) => {
            state.Motoristas = action.payload
        },
        setCurrentMotorista: (state, action: PayloadAction<IMotorista | null>) => {
            state.currentMotorista = action.payload
        },
    }
})

export const {
    setMotoristas, setCurrentMotorista
} = MotoristaSlice.actions
export default MotoristaSlice.reducer