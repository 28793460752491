import { Permissao } from "../interfaces/Permissao"
import { PermissaoField } from "../interfaces/PermissaoField"

export default function setPermissoes(fields: PermissaoField, idGrupo: number){
    const newPermissions: Permissao[] = []
    
    Object.keys(fields).forEach((key: string) => {
        newPermissions.push({
            operacao: {
                idGrupo,
                idMenu: Number(key),
                tipo: fields[Number(key)] ? 'ativar' : 'desativar'
            },
            mensagem: ''
        })
    })

    return newPermissions
}
