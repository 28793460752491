import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { handleResponse } from "@utils/response"
import { ResponsePattern } from "@utils/response/types"
import { AcessoSolicitacao } from "../interfaces/AcessoSolicitacao"
import { setAcessoSolicitacoes } from "../reducers/acessoReducer"
import { requestDeleteAcessoSolicitacao, requestGetAcessoSolicitacoes } from "../reducers/requestAcessoReducer"
import ENV from "@env/index"

export async function getAcessoSolicitacoes(dispatch: DispatchType){
    dispatch(requestGetAcessoSolicitacoes.reset())
    dispatch(requestGetAcessoSolicitacoes.loading())

    const endpoint = `${ENV.APP_ENDPOINT}/solicitacao-acesso`
    const response = await request.get<ResponsePattern<AcessoSolicitacao[]>>({ endpoint })

    handleResponse('getAcessoSolicitacoes', dispatch, response, requestGetAcessoSolicitacoes)
    .then(data => dispatch(setAcessoSolicitacoes(data)))
}

export async function deleteAcessoSolicitacao(dispatch: DispatchType, id: number){
    dispatch(requestDeleteAcessoSolicitacao.reset())
    dispatch(requestDeleteAcessoSolicitacao.loading())

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.APP_ENDPOINT}/solicitacao-acesso/excluir`
    const body = {
        id,
        dtExclusao: new Date().toISOString(),
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('deleteAcessoSolicitacao', dispatch, response, requestDeleteAcessoSolicitacao, options)
    .then(() => getAcessoSolicitacoes(dispatch))
}