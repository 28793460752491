import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { Icon as MdiIcon } from '@mdi/react'
import { mdiMenuDown } from '@mdi/js'
import { SelectItem, SelectProps } from './types'
import './styles.scss'

const Select: React.FC <SelectProps> = ({
    type,
    defaultValue,
    label,
    placeholder,
    field,
    readonly = false,
    setValue,
    setFieldValue,
    onChange,
    value,
    status,
    items
}) => {

    const labelRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)
    const [positions, setPositions] = useState<DOMRect>()

    const selectLabel = useMemo(() => {
        return placeholder ?? label ?? 'Selecionar'
    }, [label, placeholder])

    const handleOpen = useCallback(() => {
        if(readonly) return
        else{
            if(open) setOpen(false)
            else setOpen(true)
        }
    }, [open, readonly])

    const handleClick = useCallback(({ value }: SelectItem) => {
        if(!!setValue) setValue(value)
        if(!!setFieldValue && field) setFieldValue(field, value)
        if(!!onChange) onChange()
        
        setOpen(false)
    }, [setValue, setFieldValue, onChange, field])

    useEffect(() => {
        if(defaultValue){
            if(!!setValue) setValue(defaultValue)
            if(!!setFieldValue && field) setFieldValue(field, defaultValue)
        }
    }, [setValue, setFieldValue, field, defaultValue])

    useEffect(() => {
        if(labelRef && labelRef.current) setPositions(labelRef.current.getBoundingClientRect())
    }, [items, open])

    return(

        <>
            <div className = "selectWrapper">
                {!!label && <span className = "selectTitleLabel">{label}</span> }
                <div ref = {labelRef} className = {`selectLabelContainer select-${type ?? 'default'}${status && type === 'outline' ? ` status ${status}` : ''}${open ? ' open' : ''}`} onClick = {handleOpen}>
                    <span className = "selectLabel">
                        {value !== undefined ? (items.find(item => item.value === value) ? items.find(item => item.value === value)!.label : selectLabel) : selectLabel}
                    </span>
                    <span className = {`selectIcon${open ? ' open' : ''}`}>
                        <MdiIcon path = {mdiMenuDown} size = "24px" />
                    </span>
                </div>
                {(open && items.length > 0) && (
                    <ClickAwayListener onClickAway = {() => setOpen(false)}>
                        <ul 
                            className = {`selectItemsContainer scroll${open ? ' open' : ''}`} 
                            style = {{ width: positions?.width }}
                        >
                            {items.map((item, index) => 
                                <li key = {index} className = {`selectItem${item.value === value ? ' selected' : ''}`} onClick = {() => handleClick(item)}>{item.label}</li>
                            )}
                        </ul>
                    </ClickAwayListener>
                )}
            </div>
        </>

    )

}

export default Select