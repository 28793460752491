import { Armazem } from '@interfaces/Armazem'
import * as yup from 'yup'

export const formValues = {
    descricao: '',
    endereco: '',
    qtdeDocas: '',
    intervalo: '',
    listaEmail: '',

    qtdeLimiteAgendamentosDia: '',

    atendimentoManhaEntrada: '',
    atendimentoManhaSaida: '',
    atendimentoTardeEntrada: '',
    atendimentoTardeSaida: '',
}

export const formSetValues = (values?: Armazem): typeof formValues => {
    return {
        descricao: values?.descricao ?? '',
        endereco: values?.endereco ?? '',
        qtdeDocas: `${values?.qtdeDocas ?? ''}`,
        intervalo: values?.intervalo ?? '',
        listaEmail: values?.listaEmail ?? '',

        qtdeLimiteAgendamentosDia: `${values?.qtdeLimiteAgendamentosDia ?? ''}`,

        atendimentoManhaEntrada: values?.atendimentoManhaEntrada ?? '',
        atendimentoManhaSaida: values?.atendimentoManhaSaida ?? '',
        atendimentoTardeEntrada: values?.atendimentoTardeEntrada ?? '',
        atendimentoTardeSaida: values?.atendimentoTardeSaida ?? '',
    }
}

export const formSchema = yup.object().shape({
    descricao: yup.string().required('Campo obrigatório'),
    endereco: yup.string().required('Campo obrigatório'),
    qtdeDocas: yup.string().required('Campo obrigatório'),
    intervalo: yup.string().required('Campo obrigatório'),
    listaEmail: yup.string().required('Campo obrigatório'),

    qtdeLimiteAgendamentosDia: yup.string().required('Campo obrigatório'),

    atendimentoManhaEntrada: yup.string().required('Campo obrigatório'),
    atendimentoManhaSaida: yup.string().required('Campo obrigatório'),
    atendimentoTardeEntrada: yup.string().required('Campo obrigatório'),
    atendimentoTardeSaida: yup.string().required('Campo obrigatório'),
})

const formEditar = {
    values: formValues,
    setValues: formSetValues,
    schema: formSchema,
}

export default formEditar