import React from 'react'
import { useAppSelector } from '@redux/hooks'
import { jsonToParams } from '@utils/jsonToParams'

const ContaUsuariosAcesso: React.FC = () => {

    const { currentAcessoSolicitacao } = useAppSelector(s => s.acesso)
    const { cpfResponsavel, nomeResponsavel, email } = currentAcessoSolicitacao!

    return(

        <iframe
            src = {`http://app.integralogis.com.br/#/contas/?${jsonToParams({ cpfResponsavel, nomeResponsavel, email })}`}
            title = "Contas"
            width = "100%"
            height = "100%"
        />

    )

}

export default ContaUsuariosAcesso