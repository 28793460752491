import React, { useEffect } from 'react'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import FormFilter from './components/FormFilter'
import ListAgendamentos from './components/ListAgendamentos'
import { useAppDispatch } from '@redux/hooks'
import { requestGetRelatorioAgendamentosReducers } from '@modules/relatorio/reducers/requestRelatorioReducer'

const RelatorioAgendamento: React.FC = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            dispatch(requestGetRelatorioAgendamentosReducers.reset())
        }
    }, [dispatch])

    return(

        <>
            <Section>
                <SectionBox
                    title = "Relatório de agendamentos" 
                    padding = {false}
                >
                    <FormFilter />
                    <ListAgendamentos />
                </SectionBox>
            </Section>
        </>

    )

}

export default RelatorioAgendamento