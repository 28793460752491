import * as yup from 'yup'

export const createAcessoUsuariosFormValues = {
    idGrupoAcesso: null,
    nome: '',
    login: '',
    senha: '',
    email: '',
    nroDocumento: ''
}

export const createAcessoUsuariosFormSchema = yup.object().shape({
    idGrupoAcesso: yup.number().required('Campo obrigatório'),
    nome: yup.string().required('Campo obrigatório'),
    email: yup.string().email('E-mail inválido'),
    senha: yup.string().required('Campo obrigatório'),
})