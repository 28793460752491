import React from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'
import { mdiCheck, mdiPrinter } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Form'
import Button from '@components/Button'
import Select from '@components/Select'
import ButtonGroup from '@components/Button/Group'
import selectLabels from '@constants/select/labels'
import selectStatus from '@constants/select/status'
import printAgendamentoLink from '@modules/agendamento/scripts/printAgendamentoLink'
import { getAgendamento, getAgendamentos, saveAgendamento } from '@modules/agendamento/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { formSchema } from './config'

const FormSave: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { agendamento, agendamentoFilterValues } = useAppSelector(s => s.agendamento)
    const { requestSaveAgendamento } = useAppSelector(s => s.requestAgendamento)

    const TextGroup: React.FC <{title: string, text: string}> = ({ title, text }) => (
        <div className = "textGroup agendamentoConsulta">
            <span>{title}</span>
            <span>{text}</span>
        </div>
    )

    const reload = () => {
        getAgendamento(dispatch, agendamento!.id, userData!)
        getAgendamentos(dispatch, agendamentoFilterValues!, userData!)
    }

    return(

        <>
            {(!!agendamento && !!agendamentoFilterValues) && (
                <Formik
                    initialValues = {{
                        idLegenda: agendamento.tipo === 'C' ? '5' : '6',
                        idStatus: agendamento.idStatus.toString(),
                        dtStatus: agendamento.Acompanhamento.listaHistorico.length > 0 ? (agendamento.Acompanhamento.listaHistorico.at(-1)!.data as any).slice(0, 16) : '',
                        docaCarregada: agendamento.docaCarregada
                    }}
                    validationSchema = {formSchema}
                    onSubmit = {v => saveAgendamento(dispatch, v, agendamento.id, reload, userData!)}
                >
                    {({ handleSubmit, setFieldValue, values, initialValues }) => (
                        <FormikForm>
                            <Form.Container padding = {false}>
                                <Form.Section>
                                    <Form.Row columns = {3}>
                                        {/* <Select
                                            type = "outline"
                                            label = "Legenda"
                                            value = {values.idLegenda}
                                            field = "idLegenda"
                                            setFieldValue = {setFieldValue}
                                            initialValue = {initialValues.idStatus}
                                            disabledItemsRule = {true}
                                            items = {selectLabels}
                                        /> */}
                                        <Select
                                            type = "outline"
                                            label = "Status"
                                            value = {values.idStatus}
                                            field = "idStatus"
                                            setFieldValue = {setFieldValue}
                                            items = {selectStatus}
                                        />
                                        <Form.Group
                                            label = "Data Status"
                                            inputID = "dtStatus"
                                            inputName = "dtStatus"
                                            inputType = "datetime-local"
                                        />
                                        <Form.Group
                                            label = "Doca Carregada"
                                            inputID = "docaCarregada"
                                            inputName = "docaCarregada"
                                        />
                                    </Form.Row>
                                </Form.Section>
                                <Form.Section title = "Status">
                                    <Form.Row columns = {1}>
                                        <div className = "flex flex-row gap-x-6">
                                            {agendamento.Acompanhamento.listaHistorico.map((item, index) => {
                                                let tempoDeServico = ''
                                                if(agendamento.Acompanhamento.listaHistorico.length > 1 && !!agendamento.Acompanhamento.listaHistorico[index + 1]?.data){
                                                    const minutes = moment(agendamento.Acompanhamento.listaHistorico[index + 1]?.data).diff(item.data, 'minutes')
                                                    tempoDeServico = `(${moment.utc().hours(minutes / 60 | 0).minutes(minutes % 60 | 0).format("HH:mm")})`
                                                }
                                                return(
                                                    <TextGroup
                                                        key = {index}
                                                        title = {`${item.Status.descricao} ${tempoDeServico}`}
                                                        text = {`${moment(item.data).format('L')} ${moment(item.data).format('LT')}`}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </Form.Row>
                                </Form.Section>
                                <Form.Section title = "Informações">    
                                    <Form.Row columns = {1}>
                                        <div className = "grid grid-cols-2 gap-x-6 gap-y-2">
                                            <TextGroup
                                                title = "Armazém"
                                                text = {agendamento.descricaoArmazem}
                                            />
                                            <TextGroup
                                                title = "Cliente"
                                                text = {agendamento.nmCliente}
                                            />
                                            <TextGroup
                                                title = "Tipo"
                                                text = {agendamento.tipo === 'C' ? 'Carga' : 'Descarga'}
                                            />
                                            <TextGroup
                                                title = "Motorista"
                                                text = {agendamento.nomeMotorista}
                                            />
                                            <TextGroup
                                                title = "Placa"
                                                text = {agendamento.placa}
                                            />
                                            <TextGroup
                                                title = "Veículo"
                                                text = {agendamento.tipoVeiculo}
                                            />
                                        </div>
                                        <div className = "grid grid-cols-2 gap-x-6 gap-y-2">
                                            <TextGroup
                                                title = "Usuário"
                                                text = {agendamento.nomeUsuarioCadastro}
                                            />
                                            <TextGroup
                                                title = "Transportadora"
                                                text = {agendamento.nomeTransportadoraCadastro}
                                            />
                                        </div>
                                    </Form.Row>
                                </Form.Section>
                                <ButtonGroup>
                                    <Button
                                        label = "Imprimir"
                                        icon = {mdiPrinter}
                                        onClick = {() => window.open(printAgendamentoLink(agendamento.linkImpressao, userData!))}
                                    />
                                    <Button
                                        label = "Salvar"
                                        icon = {mdiCheck}
                                        status = "success"
                                        loading = {requestSaveAgendamento.loading}
                                        onClick = {handleSubmit}
                                    />
                                </ButtonGroup>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            )}
        </>

    )

}

export default FormSave